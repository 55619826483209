import { createSelector } from "reselect";

export const getFilteredData = createSelector(
    [(args) => args.data, (args) => args.filter],
    (data, filter) => {
        let filteredData = data;

        if (filter.status !== null) {
            filteredData = filteredData.filter(
                (p) => p.status === filter.status.value
            );
        }

        if (filter.country !== null) {
            filteredData = filteredData.filter(
                (p) => p.country === filter.country.value
            );
        }

        if (filter.assignedTo !== null) {
            filteredData = filteredData.filter(
                (p) => p.assigned_to === filter.assignedTo.value
            );
        }

        if (filter.lines !== null) {
            filteredData = filteredData.slice(0, filter.lines);
        }

        return filteredData;
    }
);
