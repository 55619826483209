import React from "react";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";

const TopList = ({ isLoading, data, columns, selectRow }) => {
    return (
        <Widget
            title="matching.assignment.universal.track.top_list.head"
            className="grid-left vertical-shrink mb-0 pb-0"
            isLoading={isLoading}
        >
            <Widget.Body
                className="widget-table-overflow overflow-auto"
                style={{
                    margin: "10px -20px 0px -20px",
                }}
            >
                <BootstrapTable
                    keyField="date"
                    data={data}
                    columns={columns}
                    bootstrap4
                    hover
                    striped
                    bordered={false}
                    noDataIndication={
                        <I18n t="matching.assignment.universal.track.top_list.empty" />
                    }
                    selectRow={selectRow}
                    classes="table-header-fixed table-selectable"
                />
            </Widget.Body>
        </Widget>
    );
};

export default TopList;
