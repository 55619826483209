import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import Loader from "shared/components/Loader";
import clsx from "clsx";

const ImageLoader = ({
    src,
    isProtected,
    currentClassName,
    errorMessage,
    isLoading,
    error,
    className,
    ...props
}) => {
    if (isLoading) {
        return (
            <div {...props}>
                <div className="h-100 py-2">
                    <Loader className="h-100 align-items-center" />
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div {...props}>
                <div className="row h-100 align-items-center py-2">
                    <div className="col text-center text-danger">
                        <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            size="3x"
                        />
                        {errorMessage && <p>{errorMessage}</p>}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <img
            src={src}
            className={clsx(className, currentClassName)}
            onContextMenu={isProtected && ((e) => e.preventDefault())}
            onDragStart={isProtected && ((e) => e.preventDefault())}
            {...props}
        />
    );
};

export default ImageLoader;
