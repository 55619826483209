import React, { useContext } from "react";
import I18n from "shared/lib/I18n";
import ConflictValue from "../ConflictValue/ConflictValue.container";
import CountryForm from "./CountryForm/CountryForm.container";
import GenderForm from "./GenderForm/GenderForm.container";
import TextFrom from "./TextForm/TextForm.container.js";
import Resolution from "./Resolution";
import { useLocation, useParams } from "react-router";
import { ArtistManagementContext } from "pages/ArtistManagement";

const EnhancedResolution = ({ onSuccess, setState, ...props }) => {
    const { artist } = useContext(ArtistManagementContext);
    const { locale } = useParams();
    const location = useLocation();

    const columns = [
        {
            dataField: "field_name",
            text: I18n.getTranslation(
                location,
                "artist.management.conflict.resolution.field"
            ),
            formatter: (cell) => (
                <I18n
                    t={`artist.management.conflict.resolution.field_name.${cell}`}
                    args={{
                        artistType: artist.type,
                    }}
                />
            ),
            formatExtraData: {
                locale,
            },
        },
        {
            dataField: "current_value",
            text: I18n.getTranslation(
                location,
                "artist.management.conflict.resolution.old_value"
            ),
            formatter: (cell, row) => (
                <ConflictValue field_name={row.field_name} value={cell} />
            ),
            formatExtraData: {
                locale,
            },
        },
        {
            dataField: "wikidata_value",
            text: I18n.getTranslation(
                location,
                "artist.management.conflict.resolution.wikidata_value"
            ),
            formatter: (cell, row) => (
                <ConflictValue field_name={row.field_name} value={cell} />
            ),
            formatExtraData: {
                locale,
            },
        },
        {
            dataField: "",
            isDummyField: true,
            text: I18n.getTranslation(
                location,
                "artist.management.conflict.resolution.new_value"
            ),
            formatter: (cell, row, rowIndex, data) =>
                row.field_name === "country" ||
                row.field_name === "birth_country" ||
                row.field_name === "death_country" ? (
                    <CountryForm
                        row={row}
                        setState={setState}
                        onSuccess={onSuccess}
                    />
                ) : row.field_name === "gender" ? (
                    <GenderForm
                        row={row}
                        setState={setState}
                        onSuccess={onSuccess}
                    />
                ) : (
                    <TextFrom
                        row={row}
                        setState={setState}
                        onSuccess={onSuccess}
                    />
                ),
            formatExtraData: {
                locale,
            },
        },
    ];

    return <Resolution {...props} columns={columns} />;
};

export default EnhancedResolution;
