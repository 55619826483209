import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import TopList from "./TopList";

const defaultState = {
    data: [],
    isLoading: false,
};

const EnhancedTopList = (props) => {
    const { api } = useAPI();
    const { locale, date } = useParams();
    const navigate = useNavigate();
    const [state, setState] = useState(defaultState);

    const getTops = useCallback(() => {
        let cancelled = false;

        setState((previous) => {
            return { ...previous, isLoading: true };
        });

        api.get("matching/assignment/universal/track/top")
            .then((response) => {
                if (!cancelled) {
                    setState({
                        data: response,
                        isLoading: false,
                    });
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                if (!cancelled) {
                    setState(defaultState);
                }
            });

        return () => {
            cancelled = true;
        };
    }, []);

    useEffect(getTops, [getTops]);

    const columns = [
        {
            dataField: "date",
            isDummy: true,
            text: I18n.getTranslation(
                location,
                "matching.assignment.universal.track.top_list.date"
            ),
        },
    ];

    const selectRow = {
        mode: "radio",
        clickToSelect: true,
        classes: "table-info",
        hideSelectColumn: true,
        onSelect: ({ date }, isSelect) => {
            if (isSelect) {
                navigate(
                    `/${locale}/matching/assignment/universal/track/${date}`
                );
            }
        },
        selected: [date],
    };

    return (
        <TopList
            columns={columns}
            selectRow={selectRow}
            {...state}
            {...props}
        />
    );
};

export default EnhancedTopList;
