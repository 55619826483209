import React, { useState, useEffect } from "react";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";
import { useParams, useLocation } from "react-router";
import useIntParams from "shared/hooks/useIntParams";
import WikidataModal from "./WikidataModal";
import { toast } from "react-toastify";

const EnhancedWikidataModal = ({
    firewall,
    onSuccess,
    show,
    onHide,
    isLoading,
}) => {
    const { api } = useAPI();
    const { artistId } = useIntParams();
    const { locale } = useParams();
    const location = useLocation();
    const [isAGoodMatching, setIsAGoodMatching] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (firewall) {
            const isAGoodMatching =
                firewall.wikidata_type !== null &&
                firewall.ms_type === firewall.wikidata_type &&
                (firewall.wikidata_country === null ||
                    firewall.ms_country === null ||
                    firewall.ms_country === firewall.wikidata_country);

            setIsAGoodMatching(isAGoodMatching);
        }
    }, [firewall]);

    const onMatch = () => {
        setIsSubmitting(true);
        api.post(
            `matching/ms/wikidata/${artistId}`,
            { locale },
            firewall.id_wikidata
        )
            .then((response) => {
                setIsSubmitting(false);
                toast.success(
                    I18n.getTranslation(
                        location,
                        `artist.management.wikidata.wikidataModal.update.success`
                    )
                );
                onSuccess?.();
                onHide();
                setIsAGoodMatching(null);
            })
            .catch((error) => {
                setIsSubmitting(false);
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        `artist.management.wikidata.wikidataModal.update.error`,
                        error.message
                    )
                );
            });
    };

    return (
        <WikidataModal
            show={show}
            onHide={onHide}
            isLoading={isLoading}
            isAGoodMatching={isAGoodMatching}
            setIsAGoodMatching={setIsAGoodMatching}
            firewall={firewall}
            onMatch={onMatch}
            isSubmitting={isSubmitting}
        />
    );
};

export default EnhancedWikidataModal;
