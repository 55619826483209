import React, { useCallback } from "react";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import ArtistsMatcher from "./ArtistsMatcher";

const EnhancedArtistsMatcher = ({ onChange, ...props }) => {
    const { api } = useAPI();

    const onMatch = useCallback((partner, partnerId, msId, state) => {
        api.post(
            `matching/event/francebillet/artist/${partner}`,
            {},
            { partnerId, msId, state }
        )
            .then((response) => {
                onChange(response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, []);

    const onDiscard = useCallback(
        (partner, partnerId, comment) =>
            api
                .post(
                    `matching/event/francebillet/artist/${partner}/discard/${partnerId}`,
                    {},
                    { comment }
                )
                .then((response) => {
                    onChange(response);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                }),
        []
    );

    const onRecover = useCallback(
        (partner, partnerId) =>
            api
                .delete(
                    `matching/event/francebillet/artist/${partner}/discard/${partnerId}`
                )
                .then((response) => {
                    onChange(response);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                }),
        []
    );

    return (
        <ArtistsMatcher
            onChange={onMatch}
            onDiscard={onDiscard}
            onRecover={onRecover}
            {...props}
        />
    );
};

export default EnhancedArtistsMatcher;
