import React from "react";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import "@musicstory/react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

const Log = ({ isLoading, logs, columns, options }) => {
    return (
        <Widget isLoading={isLoading}>
            <Widget.Body className="widget-table-overflow">
                <BootstrapTable
                    keyField="id"
                    data={logs}
                    columns={columns}
                    classes="table-header-fixed table-layout-auto"
                    bordered={false}
                    bootstrap4
                    striped
                    hover
                    noDataIndication={
                        <I18n t="artist.management.conflict.log.empty" />
                    }
                    pagination={paginationFactory(options)}
                />
            </Widget.Body>
        </Widget>
    );
};

export default Log;
