import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router";
import Isni from "./Isni";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";
import useIntParams from "shared/hooks/useIntParams";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
    isni: yup
        .string()
        .nullable()
        .transform((value) => value?.replace(/\s+/g, "") || null)
        .matches(/^[0-9]{15}[0-9X]$/, "utils.form.isni_format"),
});

const EnhancedIsni = (props) => {
    const { api } = useAPI();
    const { locale } = useParams();
    const [isLoading, setIsLoading] = useState(false);

    const [showISNISearch, setShowISNISearch] = useState(false);
    const onShowISNISearch = () => setShowISNISearch(true);
    const onHideISNISearch = () => setShowISNISearch(false);

    const { artistId } = useIntParams();
    const {
        handleSubmit,
        register,
        reset,
        watch,
        setValue,
        getValues,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            isni: null,
        },
    });
    const watchISNI = watch("isni");
    const setISNI = (isni) =>
        setValue("isni", isni, {
            shouldValidate: true,
            shouldDirty: true,
        });
    const getName = () => getValues("name");

    const getIsni = useCallback(() => {
        let cancelled = false;

        setIsLoading(true);
        api.get(`artist/${artistId}/isni`, { locale })
            .then((response) => {
                if (!cancelled) {
                    reset(response);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    reset();
                    setIsLoading(false);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [artistId, locale]);

    useEffect(getIsni, [getIsni]);

    const onSubmit = async (data) => {
        await api
            .put(`artist/${artistId}/isni`, { locale }, data)
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        `artist.management.isni.update.success`
                    )
                );
                reset(response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        `artist.management.isni.update.error`,
                        error.message
                    )
                );
            });
    };

    return (
        <Isni
            {...props}
            getIsni={getIsni}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            errors={errors}
            register={register}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            onSubmit={onSubmit}
            location={location}
            watchISNI={watchISNI}
            setISNI={setISNI}
            getName={getName}
            showISNISearch={showISNISearch}
            onShowISNISearch={onShowISNISearch}
            onHideISNISearch={onHideISNISearch}
        />
    );
};

export default EnhancedIsni;
