import React from "react";
import { Button, ButtonGroup, Form, InputGroup } from "react-bootstrap";
import { faCheck, faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputText from "shared/components/Form/InputText";
import I18n from "shared/lib/I18n";
import UncheckButton from "./UncheckButton";
import DiscardButton from "shared/components/DiscardButton";

const ReleaseDateForm = ({
    listId,
    songId,
    uncheckComment,
    register,
    onSave,
    onCheck,
    onDiscard,
    onRecover,
    onChange,
    errors,
    inputDisabled,
    saveDisabled,
    checkDisabled,
    discardDisabled,
    showUncheck,
    discarded,
    discardComment,
    location,
    locale,
}) => {
    return (
        <Form className="d-flex">
            <InputGroup>
                <InputText
                    {...register("release_date")}
                    error={
                        errors.release_date && [
                            I18n.getTranslation(
                                location,
                                errors.release_date.message
                            ),
                        ]
                    }
                    showError={false}
                    disabled={inputDisabled}
                />
                <InputGroup.Append>
                    <Button
                        variant="success"
                        disabled={saveDisabled}
                        onClick={onSave}
                        title={I18n.getTranslation(
                            location,
                            "utils.button.save"
                        )}
                    >
                        <FontAwesomeIcon icon={faSave} fixedWidth />
                    </Button>
                </InputGroup.Append>
            </InputGroup>
            <ButtonGroup className="ml-2">
                {showUncheck ? (
                    <UncheckButton
                        listId={listId}
                        songId={songId}
                        location={location}
                        locale={locale}
                        onChange={onChange}
                    />
                ) : (
                    <Button
                        variant="success"
                        onClick={onCheck}
                        title={
                            uncheckComment !== ""
                                ? I18n.getTranslation(
                                      location,
                                      "matching.track.track.check.commentTitle",
                                      uncheckComment
                                  )
                                : I18n.getTranslation(
                                      location,
                                      "matching.track.track.check.title"
                                  )
                        }
                        disabled={checkDisabled}
                    >
                        <FontAwesomeIcon icon={faCheck} fixedWidth />
                    </Button>
                )}
                <DiscardButton
                    discarded={discarded}
                    comment={discardComment}
                    onDiscard={onDiscard}
                    onRecover={onRecover}
                    disabled={discardDisabled}
                />
            </ButtonGroup>
        </Form>
    );
};

export default ReleaseDateForm;
