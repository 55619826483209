import React from "react";
import MatchV1 from "./MatchV1";
import MatchV2 from "./MatchV2";
import Isni from "./Isni";
import Social from "./Social";
import Site from "./Site";
import Wikidata from "./Wikidata";

const Matching = ({ getConflicts, getLogs, ...props }) => {
    return (
        <>
            <div className="row mb-5">
                <div className="col">
                    <Wikidata
                        getConflicts={getConflicts}
                        getLogs={getLogs}
                        onSuccess={() => {
                            getConflicts();
                        }}
                    />
                </div>
            </div>
            <div className="row mb-5">
                <div className="col-4">
                    <Isni />
                </div>
                <div className="col-4">
                    <Social />
                </div>
                <div className="col-4">
                    <Site />
                </div>
            </div>
            <div className="row">
                <div className="col-3">
                    <MatchV2 title="common.partners.DZ" partner="dz" />
                </div>
                <div className="col-3">
                    <MatchV2 title="common.partners.QB" partner="qb" />
                </div>
                <div className="col-3">
                    <MatchV2 title="common.partners.NA" partner="na" />
                </div>
                <div className="col-3">
                    <MatchV2 title="common.partners.IT" partner="it" />
                </div>
                <div className="col-3">
                    <MatchV2 title="common.partners.SP" partner="sp" />
                </div>
                <div className="col-3">
                    <MatchV2 title="common.partners.MB" partner="mb" />
                </div>
                <div className="col-3">
                    <MatchV1 title="common.partners.DG" partner="dg" />
                </div>
                <div className="col-3">
                    <MatchV2 title="common.partners.CJ" partner="cj" />
                </div>
                <div className="col-3">
                    <MatchV2 title="common.partners.LM" partner="lm" />
                </div>
                <div className="col-3">
                    <MatchV2 title="common.partners.7D" partner="7d" />
                </div>
            </div>
        </>
    );
};

export default Matching;
