import React from "react";
import InputSelect from "shared/components/Form/InputSelect";
import I18n from "shared/lib/I18n";

const AssignedToFilter = ({ options, value, setFilter }) => {
    return (
        <InputSelect
            onChange={(opt) => {
                setFilter((prev) => ({
                    ...prev,
                    assignedTo: opt,
                }));
            }}
            value={value}
            placeholder={
                <I18n t="matching.assignment.universal.track.track_list.filter.assigned_to.placeholder" />
            }
            options={options}
        />
    );
};

export default AssignedToFilter;
