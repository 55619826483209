import React, { useState } from "react";
import Conflicts from "./Conflicts";

const EnhancedConflicts = (props) => {
    const [selectedTab, setSelectedTab] = useState("resolution");

    const onSelectTab = (k) => setSelectedTab(k);

    return (
        <Conflicts
            {...props}
            selectedTab={selectedTab}
            onSelectTab={onSelectTab}
        />
    );
};

export default EnhancedConflicts;
