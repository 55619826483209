import React from "react";
import clsx from "clsx";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
} from "@musicstory/react-bootstrap-table2-paginator";
import ToolkitProvider from "@musicstory/react-bootstrap-table2-toolkit";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";
import AssignedToFilter from "./Filter/AssignedToFilter";
import CountryFilter from "./Filter/CountryFilter";
import StatusFilter from "./Filter/StatusFilter";
import LineFilter from "./Filter/LineFilter";
import styles from "./TrackList.module.css";
import CreateTaskButton from "./CreateTaskButton";

const TrackList = ({
    date,
    data,
    rowIds,
    isLoading,
    columns,
    defaultSorted,
    pagination,
    rowClasses,
    filter,
    setFilter,
    countryOptions,
    assignedToOptions,
    onCreateTask,
}) => {
    return (
        <ToolkitProvider keyField="id" data={data} columns={columns} search>
            {(toolkitprops) => (
                <PaginationProvider pagination={pagination}>
                    {({ paginationProps, paginationTableProps }) => (
                        <Widget
                            title={`matching.assignment.universal.track.track_list.head`}
                            isLoading={isLoading}
                            actions={
                                <PaginationListStandalone
                                    {...paginationProps}
                                />
                            }
                            className="vertical-shrink h-100"
                        >
                            <Widget.Body>
                                <div className="form-row">
                                    <div className="col-2">
                                        <StatusFilter
                                            value={filter.status}
                                            setFilter={setFilter}
                                        />
                                    </div>
                                    <div className="col-2">
                                        <CountryFilter
                                            value={filter.country}
                                            setFilter={setFilter}
                                            options={countryOptions}
                                        />
                                    </div>
                                    <div className="col-2">
                                        <AssignedToFilter
                                            value={filter.assignedTo}
                                            setFilter={setFilter}
                                            options={assignedToOptions}
                                        />
                                    </div>
                                    <div className="col-2">
                                        <LineFilter
                                            value={filter.lines}
                                            setFilter={setFilter}
                                        />
                                    </div>
                                    <div className="col text-right">
                                        <CreateTaskButton
                                            rowIds={rowIds}
                                            defaultTitle={`Universal - ${date}`}
                                            onSuccess={onCreateTask}
                                        />
                                    </div>
                                </div>
                            </Widget.Body>
                            <Widget.Body
                                className={clsx(
                                    "widget-table-overflow overflow-auto border-top border-bottom h-100",
                                    styles.body
                                )}
                            >
                                <BootstrapTable
                                    {...paginationTableProps}
                                    {...toolkitprops.baseProps}
                                    bootstrap4
                                    hover
                                    striped
                                    bordered={false}
                                    noDataIndication={
                                        <I18n t="matching.assignment.universal.track.track_list.empty" />
                                    }
                                    classes="table-header-fixed table-layout-auto mb-0"
                                    defaultSorted={defaultSorted}
                                    rowClasses={rowClasses}
                                />
                            </Widget.Body>
                            <Widget.Body>
                                <div className="row align-items-center mt-3">
                                    <>
                                        <div className="col">
                                            <PaginationTotalStandalone
                                                {...paginationProps}
                                            />
                                        </div>
                                        <div className="col">
                                            <PaginationListStandalone
                                                {...paginationProps}
                                            />
                                        </div>
                                    </>
                                </div>
                            </Widget.Body>
                        </Widget>
                    )}
                </PaginationProvider>
            )}
        </ToolkitProvider>
    );
};

export default TrackList;
