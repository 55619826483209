import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router";
import TextForm from "./TextForm";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";
import useIntParams from "shared/hooks/useIntParams";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
    field: yup.string().trim().nullable(),
});

const EnhancedTextForm = ({ row, setState, onSuccess, ...props }) => {
    const { api } = useAPI();
    const { locale } = useParams();

    const { artistId } = useIntParams();
    const {
        handleSubmit,
        register,
        control,
        reset,
        watch,
        setValue,
        getValues,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            gender: null,
        },
    });

    useEffect(() => {
        if (row) {
            setValue("field", row.wikidata_value);
        }
    }, [row.wikidata_value]);

    const onDiscard = () => {
        api.post(`artist/${artistId}/conflicts/${row.id}/discard`, {
            locale,
        })
            .then((response) => {
                setState((prev) => ({
                    ...prev,
                    conflicts: response,
                }));
                toast.success(
                    I18n.getTranslation(
                        location,
                        `artist.management.conflict.resolution.discard.success`
                    )
                );
                onSuccess?.();
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    const onSubmit = ({ field }) => {
        api.post(
            `artist/${artistId}/conflicts/${row.id}/accept`,
            { locale },
            {
                new_value: field ? field : null,
                partner: isDirty ? "Manuelle" : "Wikidata",
            }
        )
            .then((response) => {
                setState((prev) => ({
                    ...prev,
                    conflicts: response,
                }));
                toast.success(
                    I18n.getTranslation(
                        location,
                        `artist.management.conflict.resolution.accept_incoming.success`
                    )
                );
                onSuccess?.();
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    return (
        <TextForm
            {...props}
            handleSubmit={handleSubmit}
            errors={errors}
            register={register}
            control={control}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            onSubmit={onSubmit}
            onDiscard={onDiscard}
            location={location}
        />
    );
};

export default EnhancedTextForm;
