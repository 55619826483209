import React from "react";
import InputSelect from "shared/components/Form/InputSelect";
import I18n from "shared/lib/I18n";

const StatusFilter = ({ filterOptions, value, setFilter }) => {
    return (
        <InputSelect
            onChange={(opt) => {
                setFilter((prev) => ({
                    ...prev,
                    status: opt,
                }));
            }}
            value={value}
            placeholder={
                <I18n t="matching.assignment.universal.track.track_list.filter.status.placeholder" />
            }
            options={filterOptions}
        />
    );
};

export default StatusFilter;
