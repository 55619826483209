import React, { useCallback, useEffect, useState, useRef } from "react";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import useIntParams from "shared/hooks/useIntParams";
import PopularityHistogram from "./PopularityHistogram";

const defaultState = {
    gold: [],
    current: [],
    isLoading: false,
};

const EnhancedPopularityHistogram = ({setShowGold, ...props}) => {
    const { api } = useAPI();
    const { locale } = useParams();
    const { artistId } = useIntParams();
    const location = useLocation();
    const [state, setState] = useState(defaultState);

    const popoverPointerRef = useRef(null);
    const containerRef = useRef(null);
    const [popover, setPopover] = useState({
        show: false,
        left: 0,
        top: 0,
        date: null,
    });

    const getPopularity = useCallback(() => {
        setState((prev) => ({
            ...prev,
            isLoading: true,
        }));
        return api
            .get(`artist/${artistId}/popularity/histogram`, { locale })
            .then((response) => {
                setState({
                    ...response,
                    isLoading: false,
                });

                if (!response.current || response.current.length === 0) {
                    setShowGold(true);
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setState(defaultState);
            });
    }, [artistId, locale]);

    useEffect(() => {
        getPopularity();
    }, [getPopularity]);

    return (
        <PopularityHistogram
            {...props}
            {...state}
            popoverPointerRef={popoverPointerRef}
            containerRef={containerRef}
            popover={popover}
            setPopover={setPopover}
            location={location}
            setShowGold={setShowGold}
        />
    );
};

export default EnhancedPopularityHistogram;
