import { createSelector } from "reselect";

export const getFilteredData = createSelector(
    [(args) => args.data, (args) => args.filter],
    (data, filter) => {
        let filteredData = data;

        if (filter.status !== null) {
            filteredData = filteredData.filter(
                ({ checked, discarded }) =>
                    (filter.status.value === "checked" && checked) ||
                    (filter.status.value === "discarded" && discarded) ||
                    (filter.status.value === "todo" && !checked && !discarded)
            );
        }

        return filteredData;
    }
);
