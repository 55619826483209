import React from "react";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";
import { Form, Button } from "react-bootstrap";
import InputText from "shared/components/Form/InputText";
import InputSelect from "shared/components/Form/InputSelect";
import { Controller } from "react-hook-form";

const ProfileInformation = ({
    getProfileInformation,
    isLoading,
    handleSubmit,
    errors,
    register,
    control,
    isSubmitting,
    isDirty,
    onSubmit,
    location,
    artistType,
    countryOptions,
    typeOptions,
    genderOptions,
}) => {
    return (
        <Widget
            title={`artist.management.profileInformation.head`}
            enableCollapse
        >
            <Widget.Body className="pt-3 border-top">
                {isLoading ? (
                    <Loader />
                ) : (
                    <Form
                        onSubmit={!isSubmitting ? handleSubmit(onSubmit) : null}
                    >
                        <div className="row">
                            <Form.Group className="col-12">
                                <Form.Label>
                                    <I18n t="artist.management.profileInformation.type" />
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="type"
                                    render={({
                                        field: { onChange, value, ref },
                                    }) => (
                                        <InputSelect
                                            className="w-100"
                                            ref={ref}
                                            value={
                                                value !== null
                                                    ? {
                                                          value,
                                                          label: typeOptions.find(
                                                              ({ id }) =>
                                                                  String(id) ===
                                                                  String(value)
                                                          )?.name,
                                                      }
                                                    : null
                                            }
                                            options={typeOptions.map(
                                                ({ id, name }) => {
                                                    return {
                                                        value: id,
                                                        label: name,
                                                    };
                                                }
                                            )}
                                            onChange={(opt) => {
                                                onChange(
                                                    opt ? opt.value : null
                                                );
                                            }}
                                            isClearable={false}
                                        />
                                    )}
                                />
                            </Form.Group>
                            <Form.Group className="col-12">
                                <Form.Label>
                                    <I18n t="artist.management.profileInformation.name" />
                                </Form.Label>
                                <InputText
                                    {...register("name")}
                                    error={
                                        errors.name && [
                                            I18n.getTranslation(
                                                location,
                                                errors.name.message
                                            ),
                                        ]
                                    }
                                />
                            </Form.Group>
                            <Form.Group className="col-12">
                                <Form.Label>
                                    <I18n t="artist.management.profileInformation.complement" />
                                </Form.Label>
                                <InputText
                                    {...register("complement")}
                                    error={
                                        errors.complement && [
                                            I18n.getTranslation(
                                                location,
                                                errors.complement.message
                                            ),
                                        ]
                                    }
                                />
                            </Form.Group>
                            {artistType === "Personne" && (
                                <>
                                    <Form.Group className="col-12">
                                        <Form.Label>
                                            <I18n t="artist.management.profileInformation.realname" />
                                        </Form.Label>
                                        <InputText
                                            {...register("realname")}
                                            error={
                                                errors.realname && [
                                                    I18n.getTranslation(
                                                        location,
                                                        errors.realname.message
                                                    ),
                                                ]
                                            }
                                        />
                                    </Form.Group>
                                    <Form.Group className="col-12">
                                        <Form.Label>
                                            <I18n t="artist.management.profileInformation.gender" />
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="gender"
                                            render={({
                                                field: { onChange, value, ref },
                                            }) => (
                                                <InputSelect
                                                    className="w-100"
                                                    ref={ref}
                                                    value={
                                                        value !== null
                                                            ? {
                                                                  value,
                                                                  label: genderOptions.find(
                                                                      ({
                                                                          id,
                                                                      }) =>
                                                                          String(
                                                                              id
                                                                          ) ===
                                                                          String(
                                                                              value
                                                                          )
                                                                  )?.name,
                                                              }
                                                            : null
                                                    }
                                                    options={genderOptions.map(
                                                        ({ id, name }) => {
                                                            return {
                                                                value: id,
                                                                label: name,
                                                            };
                                                        }
                                                    )}
                                                    onChange={(opt) => {
                                                        onChange(
                                                            opt
                                                                ? opt.value
                                                                : null
                                                        );
                                                    }}
                                                    error={
                                                        errors.gender && [
                                                            I18n.getTranslation(
                                                                location,
                                                                errors.gender
                                                                    .message
                                                            ),
                                                        ]
                                                    }
                                                    isClearable={false}
                                                />
                                            )}
                                        />
                                    </Form.Group>
                                </>
                            )}

                            <Form.Group className="col-12">
                                <Form.Label>
                                    <I18n t="artist.management.profileInformation.country" />
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="country"
                                    render={({
                                        field: { onChange, value, ref },
                                    }) => (
                                        <InputSelect
                                            className="w-100"
                                            ref={ref}
                                            value={
                                                value !== null
                                                    ? {
                                                          value,
                                                          label: countryOptions.find(
                                                              ({ id }) =>
                                                                  String(id) ===
                                                                  String(value)
                                                          )?.nom,
                                                      }
                                                    : null
                                            }
                                            options={countryOptions.map(
                                                ({ id, nom }) => {
                                                    return {
                                                        value: id,
                                                        label: nom,
                                                    };
                                                }
                                            )}
                                            onChange={(opt) => {
                                                onChange(
                                                    opt ? opt.value : null
                                                );
                                            }}
                                            isClearable={false}
                                        />
                                    )}
                                />
                            </Form.Group>
                        </div>
                        <div className="d-flex flex-row-reverse">
                            <Button
                                variant="secondary ml-2"
                                disabled={isSubmitting || isLoading}
                                onClick={
                                    !isSubmitting && !isLoading
                                        ? getProfileInformation
                                        : null
                                }
                                title={I18n.getTranslation(
                                    location,
                                    "artist.management.profileInformation.reset.title"
                                )}
                            >
                                <I18n t="utils.button.reset" />
                            </Button>
                            <Button
                                variant="success"
                                disabled={isSubmitting || isLoading || !isDirty}
                                onClick={
                                    !isSubmitting && !isLoading && isDirty
                                        ? handleSubmit(onSubmit)
                                        : null
                                }
                                title={I18n.getTranslation(
                                    location,
                                    `artist.management.profileInformation.update.title`
                                )}
                            >
                                {isSubmitting ? (
                                    <Loader size="1x" />
                                ) : (
                                    <I18n t={`utils.button.edit`} />
                                )}
                            </Button>
                        </div>
                    </Form>
                )}
            </Widget.Body>
        </Widget>
    );
};

export default ProfileInformation;
