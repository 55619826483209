import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import I18n from "shared/lib/I18n";
import InputSelect from "shared/components/Form/InputSelect";

const AlbumMatcher = ({ album, options, onChange, location, disabled }) => {
    return (
        <div className="d-flex align-items-center">
            <InputSelect
                value={
                    album !== null
                        ? {
                              value: album.id,
                              label: `${album.title} - ${album.type} - ${album.format}`,
                          }
                        : null
                }
                options={options}
                onChange={onChange}
                menuPlacement="auto"
                enablePortal
                isClearable={false}
                isDisabled={disabled}
                className="flex-grow-1"
            />
            {album && album?.type !== "ORIGINAL" && (
                <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    size="lg"
                    className="text-danger ml-3"
                    title={I18n.getTranslation(
                        location,
                        "matching.track.track.ms_album_not_original"
                    )}
                />
            )}
        </div>
    );
};

export default AlbumMatcher;
