import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router";
import CountryForm from "./CountryForm";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";
import useIntParams from "shared/hooks/useIntParams";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
    country: yup.number().required("utils.form.required"),
});

const EnhancedCountryForm = ({ row, setState, onSuccess, ...props }) => {
    const { api } = useAPI();
    const { locale } = useParams();

    const { artistId } = useIntParams();
    const {
        handleSubmit,
        register,
        control,
        reset,
        watch,
        setValue,
        getValues,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            country: null,
        },
    });

    const [countryOptions, setCountryOptions] = useState([]);
    const getCountry = useCallback(() => {
        api.get(`country/${locale}`)
            .then((response) => {
                setCountryOptions(response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [locale]);

    useEffect(getCountry, [getCountry]);

    useEffect(() => {
        if (countryOptions) {
            const id = countryOptions.find(
                (option) => option.nom === row.wikidata_value
            )?.id;

            if (id) {
                setValue("country", id);
            }
        }
    }, [countryOptions]);

    const onDiscard = () => {
        api.post(`artist/${artistId}/conflicts/${row.id}/discard`, {
            locale,
        })
            .then((response) => {
                setState((prev) => ({
                    ...prev,
                    conflicts: response,
                }));
                toast.success(
                    I18n.getTranslation(
                        location,
                        `artist.management.conflict.resolution.discard.success`
                    )
                );
                onSuccess?.();
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    const onSubmit = ({ country }) => {
        api.post(
            `artist/${artistId}/conflicts/${row.id}/accept`,
            { locale },
            {
                new_value: country.toString(),
                partner: isDirty ? "Manuelle" : "Wikidata",
            }
        )
            .then((response) => {
                setState((prev) => ({
                    ...prev,
                    conflicts: response,
                }));
                toast.success(
                    I18n.getTranslation(
                        location,
                        `artist.management.conflict.resolution.accept_incoming.success`
                    )
                );
                onSuccess?.();
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    return (
        <CountryForm
            {...props}
            handleSubmit={handleSubmit}
            errors={errors}
            register={register}
            control={control}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            onSubmit={onSubmit}
            onDiscard={onDiscard}
            location={location}
            countryOptions={countryOptions}
        />
    );
};

export default EnhancedCountryForm;
