import React, { useCallback } from "react";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import RecordingMatcher from "./RecordingMatcher";

const EnhancedRecordingMatcher = ({
    listId,
    songId,
    onChange,
    locale,
    ...props
}) => {
    const { api } = useAPI();

    const onMatch = useCallback(
        (opt, action) => {
            if (
                action.action !== "select-option" &&
                action.action !== "clear"
            ) {
                return;
            }

            api.post(
                `matching/track/universal/list/${listId}/recording`,
                { locale },
                {
                    partnerId: songId,
                    msId:
                        action.action === "select-option"
                            ? opt.value.id
                            : action.removedValues[0].value.id,
                    state:
                        action.action === "select-option" ? "VALID" : "INVALID",
                }
            )
                .then((response) => {
                    onChange(response);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                });
        },
        [listId, songId, locale]
    );

    return <RecordingMatcher onChange={onMatch} locale={locale} {...props} />;
};

export default EnhancedRecordingMatcher;
