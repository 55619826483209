import DeezerTrack from "./Track/DeezerTrack";
import LineMusicTrack from "./Track/LineMusicTrack";
import SnepTrack from "./Track/SnepTrack";
import UniversalTrack from "./Track/UniversalTrack";

export const PARTNERS = [
    { id: "deezer", name: "Deezer" },
    { id: "linemusic", name: "Line Music" },
    { id: "snep", name: "Snep" },
    { id: "universal", name: "Universal" },
];

export const PARTNERS_TRACK_COMPONENT = {
    deezer: DeezerTrack,
    linemusic: LineMusicTrack,
    snep: SnepTrack,
    universal: UniversalTrack,
};
