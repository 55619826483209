import React from "react";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import ImageLoader from "shared/components/ImageLoader";
import InputSelect from "shared/components/Form/InputSelect";
import DetailModal from "./DetailModal";
import "./Gallery.css";

const Gallery = ({
    gallery,
    filteredGallery,
    isLoading,
    filterOptions,
    selectedFilterOptions,
    setSelectedFilterOptions,
    detailState,
    handleShow,
    handleHide,
    setFailId,
}) => {
    return (
        <>
            <Widget
                title="artist.view.imagesGallery.head"
                className="artist-view-gallery"
                isLoading={isLoading}
            >
                <Widget.Body className="border-top">
                    <InputSelect
                        className="my-3"
                        value={selectedFilterOptions}
                        onChange={(opt) => {
                            setSelectedFilterOptions(opt ?? null);
                        }}
                        placeholder={
                            <I18n t="artist.view.imagesGallery.tags" />
                        }
                        options={filterOptions}
                        closeMenuOnSelect={false}
                        isDisabled={gallery.length === 0}
                        isMulti
                        enablePortal
                    />
                </Widget.Body>
                <Widget.Body className="border-top">
                    {filteredGallery.length !== 0 ? (
                        <div className="artist-view-gallery-grid-container mt-3">
                            {filteredGallery.map(
                                ({
                                    id_picture,
                                    id_picture_version,
                                    url,
                                    source,
                                }) => (
                                    <div
                                        key={id_picture_version}
                                        className="artist-view-gallery-picture border"
                                        onClick={() =>
                                            handleShow(
                                                id_picture,
                                                id_picture_version
                                            )
                                        }
                                    >
                                        <ImageLoader
                                            isProtected
                                            className="artist-view-gallery-img"
                                            src={url}
                                            onFail={() =>
                                                setFailId(id_picture_version)
                                            }
                                        />
                                        <span className="artist-view-gallery-img-source">
                                            {source}
                                        </span>
                                    </div>
                                )
                            )}
                        </div>
                    ) : (
                        <h2 className="text-center mt-4">
                            <I18n t="artist.view.imagesGallery.empty" />
                        </h2>
                    )}
                </Widget.Body>
            </Widget>
            {detailState.show && (
                <DetailModal {...detailState} handleHide={handleHide} />
            )}
        </>
    );
};

export default Gallery;
