import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faDesktop } from "@fortawesome/free-solid-svg-icons";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";
import { Form, Button, InputGroup } from "react-bootstrap";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import InputText from "shared/components/Form/InputText";
import Error from "shared/components/Form/Error";
import WikidataModal from "./WikidataModal";
import clsx from "clsx";

const Wikidata = ({
    isLoading,
    partnerId,
    artistName,
    isModalLoading,
    firewall,
    handleSubmit,
    errors,
    register,
    isSubmitting,
    isDirty,
    onSubmit,
    location,
    showISNISearch,
    onHideISNISearch,
    handleDelete,
    getWikidata,
    getConflicts,
    getLogs,
}) => {
    return (
        <Widget
            title={`artist.management.wikidata.head`}
            enableCollapse
            style={{ height: "100% !important", maxHeight: "150px" }}
        >
            <Widget.Body className="pt-3 border-top">
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        {!partnerId && (
                            <Form
                                onSubmit={
                                    !isSubmitting
                                        ? handleSubmit(onSubmit)
                                        : null
                                }
                            >
                                <div className="row">
                                    <Form.Group className="col-12">
                                        <InputGroup>
                                            <InputText
                                                {...register("wikipedia")}
                                                showError={false}
                                                disabled={partnerId !== null}
                                                placeholder={I18n.getTranslation(
                                                    location,
                                                    "artist.management.wikidata.placeholder"
                                                )}
                                                error={
                                                    errors.wikidata && [
                                                        I18n.getTranslation(
                                                            location,
                                                            errors.wikidata
                                                                .message
                                                        ),
                                                    ]
                                                }
                                            />
                                            <InputGroup.Append>
                                                <Button
                                                    variant="outline-primary"
                                                    title={I18n.getTranslation(
                                                        location,
                                                        "artist.management.wikidata.update.title"
                                                    )}
                                                    onClick={
                                                        !isSubmitting &&
                                                        !isLoading &&
                                                        isDirty &&
                                                        partnerId === null
                                                            ? handleSubmit(
                                                                  onSubmit
                                                              )
                                                            : null
                                                    }
                                                    disabled={
                                                        partnerId !== null
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faSearch}
                                                        fixedWidth
                                                    />
                                                </Button>
                                            </InputGroup.Append>
                                            <Error
                                                error={
                                                    errors.wikidata && [
                                                        I18n.getTranslation(
                                                            location,
                                                            errors.wikidata
                                                                .message
                                                        ),
                                                    ]
                                                }
                                                className="mt-3"
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                </div>
                            </Form>
                        )}
                        {partnerId && (
                            <div className="d-flex align-items-center">
                                <InputGroup className="mr-2">
                                    <InputText
                                        defaultValue={`https://www.wikidata.org/wiki/${partnerId}`}
                                        disabled
                                    />
                                    <InputGroup.Append>
                                        <a
                                            href={`https://www.wikidata.org/wiki/${partnerId}`}
                                            className={clsx(
                                                "btn",
                                                "btn-outline-primary"
                                            )}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FontAwesomeIcon
                                                icon={faDesktop}
                                                fixedWidth
                                            />
                                        </a>
                                    </InputGroup.Append>
                                </InputGroup>
                                <Button
                                    variant="danger"
                                    onClick={handleDelete}
                                    title={I18n.getTranslation(
                                        location,
                                        "artist.management.wikidata.del.title"
                                    )}
                                >
                                    <FontAwesomeIcon
                                        icon={faTrashAlt}
                                        fixedWidth
                                    />
                                </Button>
                            </div>
                        )}
                    </>
                )}
                <WikidataModal
                    show={showISNISearch}
                    onHide={onHideISNISearch}
                    firewall={firewall}
                    isLoading={isModalLoading}
                    onSuccess={() => {
                        getWikidata();
                        getConflicts();
                        getLogs();
                    }}
                />
            </Widget.Body>
        </Widget>
    );
};

export default Wikidata;
