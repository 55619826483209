import React, { useCallback } from "react";
import { toast } from "react-toastify";
import { useDebouncedCallback } from "use-debounce";
import useAPI from "shared/hooks/useApi";
import SongkickEventMatcher from "./SongkickEventMatcher";

const EnhancedSongkickEventMatcher = ({
    francebilletId,
    date,
    onChange,
    ...props
}) => {
    const { api } = useAPI();

    const search = useDebouncedCallback((term, callback) => {
        api.get("searchengine/songkick/event", {
            term,
            date,
        })
            .then((response) => {
                callback(
                    response.map((row) => {
                        const label = [
                            row.name,
                            row.date,
                            row.time,
                            row.venue?.country,
                            row.venue?.city,
                        ];
                        return {
                            value: row,
                            label: label.filter(Boolean).join(" - "),
                        };
                    })
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, 750);

    const onMatch = useCallback((value, action) => {
        if (
            action.action !== "select-option" &&
            action.action != "remove-value"
        ) {
            return;
        }

        api.post(
            "matching/event/francebillet/event",
            {},
            {
                francebilletId,
                songkickId:
                    action.action === "select-option"
                        ? action.option.value.id
                        : action.removedValue.value.id,
                state: action.action === "select-option" ? "VALID" : "INVALID",
            }
        )
            .then((response) => {
                onChange(response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, []);

    const onDiscard = useCallback(
        (comment) =>
            api
                .post(
                    `matching/event/francebillet/event/discard/${francebilletId}`,
                    {},
                    { comment }
                )
                .then((response) => {
                    onChange(response);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                }),
        [francebilletId]
    );

    const onRecover = useCallback(
        () =>
            api
                .delete(
                    `matching/event/francebillet/event/discard/${francebilletId}`
                )
                .then((response) => {
                    onChange(response);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                }),
        [francebilletId]
    );

    return (
        <SongkickEventMatcher
            loadOptions={search}
            onChange={onMatch}
            onDiscard={onDiscard}
            onRecover={onRecover}
            {...props}
        />
    );
};

export default EnhancedSongkickEventMatcher;
