import React from "react";
import { Form, Button, Modal } from "react-bootstrap";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import I18n from "shared/lib/I18n";
import InputText from "shared/components/Form/InputText";
import Loader from "shared/components/Loader";

const WikidataModal = ({
    show,
    onHide,
    isAGoodMatching,
    setIsAGoodMatching,
    isLoading,
    firewall,
    onMatch,
    isSubmitting,
}) => {
    return (
        <Modal
            show={show}
            onHide={() => {
                onHide();
                setIsAGoodMatching(null);
            }}
            size="lg"
            scrollable
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <I18n t="artist.management.wikidata.wikidataModal.head" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body
                className="p-3 d-flex align-items-center justify-content-center"
                style={{ minHeight: "200px" }}
            >
                {isLoading ? (
                    <Loader />
                ) : isAGoodMatching === true ? (
                    <div className="text-center">
                        <div style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                            <I18n
                                t="artist.management.wikidata.wikidataModal.matchingCorrect.title"
                                args={firewall.id_wikidata}
                            />
                        </div>
                        <div style={{ fontSize: "1rem", marginTop: "0.5rem" }}>
                            <I18n t="artist.management.wikidata.wikidataModal.matchingCorrect.body" />
                        </div>
                    </div>
                ) : isAGoodMatching === false ? (
                    <div className="d-flex flex-column">
                        <div className="text-center">
                            <div
                                style={{
                                    fontSize: "1.5rem",
                                    fontWeight: "bold",
                                }}
                            >
                                <I18n
                                    t="artist.management.wikidata.wikidataModal.matchingIncorrect.title"
                                    args={firewall.id_wikidata}
                                />
                            </div>
                            <div
                                style={{
                                    fontSize: "1rem",
                                    marginTop: "0.5rem",
                                }}
                            >
                                <I18n t="artist.management.wikidata.wikidataModal.matchingIncorrect.body" />
                            </div>
                        </div>
                        <table className="table table-bordered mt-3">
                            <thead>
                                <tr>
                                    <th>
                                        {I18n.getTranslation(
                                            location,
                                            `artist.management.wikidata.wikidataModal.field`
                                        )}
                                    </th>
                                    <th>Music Story</th>
                                    <th>
                                        {I18n.getTranslation(
                                            location,
                                            `artist.management.wikidata.wikidataModal.wikidata`
                                        )}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {I18n.getTranslation(
                                            location,
                                            `artist.management.wikidata.wikidataModal.type`
                                        )}
                                    </td>
                                    <td>
                                        {firewall.ms_type
                                            ? I18n.getTranslation(
                                                  location,
                                                  `artist.management.wikidata.wikidataModal.${firewall.ms_type}`
                                              )
                                            : "-"}
                                    </td>
                                    <td>
                                        {firewall.wikidata_type
                                            ? I18n.getTranslation(
                                                  location,
                                                  `artist.management.wikidata.wikidataModal.${firewall.wikidata_type}`
                                              )
                                            : "-"}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {I18n.getTranslation(
                                            location,
                                            `artist.management.wikidata.wikidataModal.country`
                                        )}
                                    </td>
                                    <td>{firewall.ms_country || "-"}</td>
                                    <td>{firewall.wikidata_country || "-"}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                ) : null}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => {
                        onHide();
                        setIsAGoodMatching(null);
                    }}
                >
                    <I18n t="utils.button.close" />
                </Button>
                {isAGoodMatching && (
                    <Button
                        variant="success"
                        disabled={!firewall}
                        onClick={onMatch}
                    >
                        {isSubmitting ? (
                            <Loader size="1x" />
                        ) : (
                            <I18n t="utils.button.validate" />
                        )}
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default WikidataModal;
