import React, { useContext } from "react";
import ProfileInformation from "./ProfileInformation";
import Names from "./Names";
import Alias from "./Alias";
import Member from "./Member";
import Group from "./Group";
import Genre from "./Genre";
import Role from "./Role";
import { ProfileContext } from "pages/ArtistManagement/Profile";
import { TYPE_BAND, TYPE_PERSON } from "shared/constants/cms/artist";

const Profile = () => {
    const { artistType } = useContext(ProfileContext);
    return (
        <div className="row">
            <div className="col">
                <ProfileInformation />
                <Names />
                <Alias />
            </div>
            <div className={TYPE_BAND === artistType ? "col-9" : "col-6"}>
                <Genre />
                {TYPE_BAND === artistType && <Member />}
                {TYPE_PERSON === artistType && <Group />}
            </div>
            <div className="col-3">
                {TYPE_PERSON === artistType && <Role />}
            </div>
        </div>
    );
};

export default Profile;
