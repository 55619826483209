import React from "react";
import InputNumber from "shared/components/Form/InputNumber/InputNumber";
import I18n from "shared/lib/I18n";

const LineFilter = ({ value, setFilter }) => {
    return (
        <InputNumber
            value={value}
            onChange={(e) => {
                setFilter((prev) => ({
                    ...prev,
                    lines: e.target.value || null,
                }));
            }}
            placeholder={I18n.getTranslation(
                location,
                "matching.assignment.universal.track.track_list.filter.lines.placeholder"
            )}
            min="0"
        />
    );
};

export default LineFilter;
