import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate, useLocation } from "react-router";
import useAPI from "shared/hooks/useApi";
import useIntParams from "shared/hooks/useIntParams";
import ArtistManagement from "./ArtistManagement";
import { ArtistManagementProvider } from "./ArtistManagement.provider";
import useUser from "shared/hooks/useUser";
import { toast } from "react-toastify";

const defaultConflictState = {
    isConflictLoading: false,
    conflicts: [],
};

const defaultLogState = {
    isLogLoading: false,
    logs: [],
};

const EnhancedArtistManagement = (props) => {
    const { api } = useAPI();
    const { locale, selectedTab } = useParams();
    const { artistId } = useIntParams();
    const navigate = useNavigate();
    const { user } = useUser();
    const location = useLocation();
    const [conflictState, setConflictState] = useState(defaultConflictState);
    const [logState, setLogState] = useState(defaultLogState);

    const onSelectTab = (k) => {
        navigate(`/${locale}/artist/${artistId}/edit/${k}`);
    };

    const getConflicts = useCallback(() => {
        let cancelled = false;

        setConflictState((prev) => ({
            ...prev,
            isConflictLoading: true,
        }));
        api.get(`artist/${artistId}/conflicts`, { locale })
            .then((response) => {
                if (!cancelled) {
                    setConflictState({
                        isConflictLoading: false,
                        conflicts: response,
                    });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    setConflictState(defaultConflictState);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [artistId, locale]);

    useEffect(getConflicts, [getConflicts]);

    const getLogs = useCallback(() => {
        let cancelled = false;

        setLogState((prev) => ({
            ...prev,
            isLoading: true,
        }));
        api.get(`artist/${artistId}/conflicts/log`, { locale })
            .then((response) => {
                if (!cancelled) {
                    setLogState({
                        isLogLoading: false,
                        logs: response,
                    });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    setLogState(defaultLogState);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [artistId]);

    useEffect(getLogs, [getLogs]);

    return (
        <ArtistManagementProvider>
            <ArtistManagement
                {...props}
                {...conflictState}
                {...logState}
                setConflictState={setConflictState}
                selectedTab={selectedTab}
                onSelectTab={onSelectTab}
                user={user}
                location={location}
                getConflicts={getConflicts}
                getLogs={getLogs}
            />
        </ArtistManagementProvider>
    );
};

export default EnhancedArtistManagement;
