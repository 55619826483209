import React from "react";
import clsx from "clsx";
import styles from "./ConflictValue.module.css";

const ConflictValue = ({ field_name, value }) => {
    if (field_name === "wikipedia" || field_name === "websites") {
        return (
            <div className={clsx(styles.textWrap)}>
                {Array.isArray(value)
                    ? value.map((url, index) => (
                          <a
                              key={index}
                              href={url}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ display: "block" }}
                          >
                              {url}
                          </a>
                      ))
                    : "-"}
            </div>
        );
    }

    if (
        [
            "facebook",
            "twitter",
            "instagram",
            "soundcloud",
            "youtube",
            "tiktok",
        ].includes(field_name)
    ) {
        return value ? (
            <a href={value} target="_blank" rel="noopener noreferrer">
                {value}
            </a>
        ) : (
            "-"
        );
    }

    if (field_name === "isni") {
        return value ? (
            <a
                href={`https://isni.org/isni/${value}`}
                target="_blank"
                rel="noopener noreferrer"
            >
                {value}
            </a>
        ) : (
            "-"
        );
    }

    if (field_name === "aliases") {
        return <span>{Array.isArray(value) ? value.join(", ") : "-"}</span>;
    }

    return value !== null ? value : "-";
};

export default ConflictValue;
