import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import {
    faApple,
    faDeezer,
    faSpotify,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import UniversalTrack from "./UniversalTrack";
import RecordingMatcher from "./RecordingMatcher";
import AlbumMatcher from "./AlbumMatcher";
import GenreMatcher from "./GenreMatcher";
import ReleaseDateForm from "./ReleaseDateForm";
import { getFilteredData } from "./UniversalTrack.selector";

const defaultState = {
    data: [],
    isLoading: false,
};

const EnhancedUniversalTrack = (props) => {
    const { api } = useAPI();
    const location = useLocation();
    const { partnerId, listId, locale } = useParams();
    const [{ data, isLoading }, setState] = useState(defaultState);
    const [filter, setFilter] = useState({
        status: null,
    });

    const fetchUniversalTrack = useCallback(() => {
        let cancelled = false;
        setState((prev) => ({ ...prev, isLoading: true }));
        api.get(`matching/track/${partnerId}/list/${listId}`, { locale })
            .then((response) => {
                if (!cancelled) {
                    setState({ data: response, isLoading: false });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    setState(defaultState);
                    console.error(error);
                    toast.error(error.message);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [listId]);

    useEffect(() => {
        fetchUniversalTrack();
    }, [fetchUniversalTrack]);

    const updateRows = (newData) => {
        setState((prev) => ({
            ...prev,
            data: prev.data.map(
                (row) => newData.find(({ id }) => id === row.id) ?? row
            ),
        }));
    };

    const columns = [
        {
            dataField: "rank",
            text: I18n.getTranslation(location, "matching.track.track.rank"),
            sort: true,
            searchable: false,
            headerStyle: {
                width: "5rem",
            },
        },
        {
            dataField: "artist_name",
            text: I18n.getTranslation(location, "matching.track.track.artist"),
            formatter: (cell) => {
                return cell ?? "-";
            },
            searchable: false,
        },
        {
            dataField: "title",
            text: I18n.getTranslation(location, "matching.track.track.title"),
            formatter: (cell) => {
                return cell ?? "-";
            },
        },
        {
            dataField: "ms_recording",
            text: I18n.getTranslation(
                location,
                "matching.track.track.recording"
            ),
            formatter: (
                cell,
                { artist_name, title, id_song },
                rowIndex,
                { locale, listId }
            ) => {
                return (
                    <RecordingMatcher
                        listId={listId}
                        songId={id_song}
                        recording={cell}
                        defaultSearch={`${artist_name} ${title}`}
                        locale={locale}
                        onChange={updateRows}
                    />
                );
            },
            formatExtraData: {
                locale,
                listId,
            },
        },
        {
            dataField: "links.spotify",
            text: "",
            formatter: (cell) =>
                cell && (
                    <a href={cell} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faSpotify} size="lg" />
                    </a>
                ),
            classes: "pr-1",
        },
        {
            dataField: "links.deezer",
            text: "",
            formatter: (cell) =>
                cell && (
                    <a href={cell} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faDeezer} size="lg" />
                    </a>
                ),
            classes: "px-1",
        },
        {
            dataField: "links.apple",
            text: "",
            formatter: (cell) =>
                cell && (
                    <a href={cell} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faApple} size="lg" />
                    </a>
                ),
            classes: "pl-1",
        },
        {
            dataField: "ms_album",
            text: I18n.getTranslation(
                location,
                "matching.track.track.ms_album"
            ),
            formatter: (
                cell,
                { ms_album_candidates, id_song },
                rowIndex,
                { locale, location, listId }
            ) => {
                return (
                    <AlbumMatcher
                        listId={listId}
                        songId={id_song}
                        album={cell}
                        candidates={ms_album_candidates}
                        locale={locale}
                        location={location}
                        onChange={updateRows}
                    />
                );
            },
            formatExtraData: {
                locale,
                location,
                listId,
            },
        },
        {
            dataField: "genre",
            text: I18n.getTranslation(location, "matching.track.track.genre"),
            formatter: (
                cell,
                { ms_recording },
                rowIndex,
                { locale, location, listId }
            ) => {
                return (
                    <GenreMatcher
                        listId={listId}
                        recordingId={ms_recording?.id}
                        genre={cell}
                        locale={locale}
                        location={location}
                        onChange={updateRows}
                    />
                );
            },
            formatExtraData: {
                locale,
                location,
                listId,
            },
        },
        {
            dataField: "release_date",
            isDummyField: true,
            text: I18n.getTranslation(
                location,
                "matching.track.track.release_date"
            ),
            formatter: (
                cell,
                {
                    ms_recording,
                    ms_album,
                    genre,
                    id_song,
                    checked,
                    uncheck_comment,
                    discarded,
                    discard_comment,
                },
                rowIndex,
                { locale, location, listId }
            ) => {
                return (
                    <ReleaseDateForm
                        listId={listId}
                        songId={id_song}
                        recording={ms_recording}
                        album={ms_album}
                        genre={genre}
                        checked={checked}
                        uncheckComment={uncheck_comment}
                        discarded={discarded}
                        discardComment={discard_comment}
                        locale={locale}
                        location={location}
                        onChange={updateRows}
                    />
                );
            },
            formatExtraData: {
                locale,
                location,
                listId,
            },
        },
    ];

    const defaultSorted = [
        {
            dataField: "rank",
            order: "asc",
        },
    ];

    const pagination = paginationFactory({
        custom: true,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        sizePerPage: 50,
        nextPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.next_page"
        ),
        prePageTitle: I18n.getTranslation(
            location,
            "utils.pagination.previous_page"
        ),
        firstPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.first_page"
        ),
        lastPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.last_page"
        ),
        paginationTotalRenderer: (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                <I18n t="utils.pagination.total" args={{ size }} />
            </span>
        ),
    });

    const rowClasses = ({ checked, unchecked, discarded }) => {
        if (checked) {
            return "table-success";
        } else if (discarded) {
            return "table-secondary";
        } else if (unchecked) {
            return "table-danger";
        }
    };

    return (
        <UniversalTrack
            data={getFilteredData({ data, filter })}
            isLoading={isLoading}
            columns={columns}
            defaultSorted={defaultSorted}
            pagination={pagination}
            rowClasses={rowClasses}
            filter={filter}
            setFilter={setFilter}
            {...props}
        />
    );
};

export default EnhancedUniversalTrack;
