import React from "react";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";
import { Form, Button, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDesktop, faSearch } from "@fortawesome/free-solid-svg-icons";
import InputText from "shared/components/Form/InputText";
import Error from "shared/components/Form/Error";
import clsx from "clsx";
import ISNISearchModal from "./ISNISearchModal";

const Isni = ({
    getIsni,
    isLoading,
    handleSubmit,
    errors,
    register,
    isSubmitting,
    isDirty,
    onSubmit,
    location,
    watchISNI,
    setISNI,
    getName,
    showISNISearch,
    onShowISNISearch,
    onHideISNISearch,
}) => {
    return (
        <Widget
            title={`artist.management.isni.head`}
            enableCollapse
            style={{ height: "100% !important" }}
        >
            <Widget.Body className="pt-3 border-top">
                {isLoading ? (
                    <Loader />
                ) : (
                    <Form
                        onSubmit={!isSubmitting ? handleSubmit(onSubmit) : null}
                    >
                        <div className="row" style={{ minHeight: "246px" }}>
                            <Form.Group className="col-12">
                                <InputGroup>
                                    <InputText
                                        {...register("isni")}
                                        showError={false}
                                        error={
                                            errors.isni && [
                                                I18n.getTranslation(
                                                    location,
                                                    errors.isni.message
                                                ),
                                            ]
                                        }
                                    />
                                    <InputGroup.Append>
                                        <Button
                                            variant="outline-primary"
                                            title={I18n.getTranslation(
                                                location,
                                                "artist.management.isni.isniSearch.tooltip"
                                            )}
                                            onClick={onShowISNISearch}
                                        >
                                            <FontAwesomeIcon
                                                icon={faSearch}
                                                fixedWidth
                                            />
                                        </Button>
                                    </InputGroup.Append>
                                    <InputGroup.Append>
                                        <a
                                            href={
                                                watchISNI
                                                    ? `https://isni.org/isni/${watchISNI}`
                                                    : null
                                            }
                                            className={clsx(
                                                "btn",
                                                "btn-outline-primary",
                                                watchISNI ? null : "disabled"
                                            )}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FontAwesomeIcon
                                                icon={faDesktop}
                                                fixedWidth
                                            />
                                        </a>
                                    </InputGroup.Append>
                                    <Error
                                        error={
                                            errors.isni && [
                                                I18n.getTranslation(
                                                    location,
                                                    errors.isni.message
                                                ),
                                            ]
                                        }
                                        className="mt-3"
                                    />
                                </InputGroup>
                            </Form.Group>
                        </div>
                        <div className="d-flex flex-row-reverse">
                            <Button
                                variant="secondary ml-2"
                                disabled={isSubmitting || isLoading}
                                onClick={
                                    !isSubmitting && !isLoading ? getIsni : null
                                }
                                title={I18n.getTranslation(
                                    location,
                                    "artist.management.isni.reset.title"
                                )}
                            >
                                <I18n t="utils.button.reset" />
                            </Button>
                            <Button
                                variant="success"
                                disabled={isSubmitting || isLoading || !isDirty}
                                onClick={
                                    !isSubmitting && !isLoading && isDirty
                                        ? handleSubmit(onSubmit)
                                        : null
                                }
                                title={I18n.getTranslation(
                                    location,
                                    `artist.management.isni.update.title`
                                )}
                            >
                                {isSubmitting ? (
                                    <Loader size="1x" />
                                ) : (
                                    <I18n t={`utils.button.edit`} />
                                )}
                            </Button>
                        </div>
                    </Form>
                )}
                <ISNISearchModal
                    show={showISNISearch}
                    onHide={onHideISNISearch}
                    setISNI={setISNI}
                    getName={getName}
                />
            </Widget.Body>
        </Widget>
    );
};

export default Isni;
