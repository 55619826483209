import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import DeezerTrack from "./DeezerTrack";
import { useLocation, useParams } from "react-router";
import I18n from "shared/lib/I18n";
import RecordingMatcher from "./RecordingMatcher";
import LyricfindMatcher from "./LyricfindMatcher";
import RequestAction from "./Action/RequestAction";
import DiscardAction from "./Action/DiscardAction";
import CreateAction from "./Action/CreateAction";
import { getFilteredData } from "./DeezerTrack.selector";
import ArtistSelect from "shared/components/ArtistSelect";
import { ButtonGroup } from "react-bootstrap";

const defaultState = {
    data: [],
    isLoading: false,
};

const EnhancedDeezerTrack = (props) => {
    const { api } = useAPI();
    const location = useLocation();
    const { partnerId, listId, locale } = useParams();
    const [{ data, isLoading }, setState] = useState(defaultState);
    const [filter, setFilter] = useState({
        matched: null,
        discarded: null,
    });
    const [query, setQuery] = useState("");

    const fetchDeezerTrack = useCallback(() => {
        let cancelled = false;
        setState((prev) => ({ ...prev, isLoading: true }));
        api.get(`matching/track/${partnerId}/list/${listId}`, {
            q: query,
        })
            .then((response) => {
                if (!cancelled) {
                    setState({ data: response, isLoading: false });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    setState(defaultState);
                    console.error(error);
                    toast.error(error.message);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [listId, query]);

    useEffect(() => {
        fetchDeezerTrack();
    }, [fetchDeezerTrack]);

    const updateRow = (rowId, data) => {
        setState((prev) => ({
            ...prev,
            data: prev.data.map((row) =>
                row.id === rowId ? { ...row, ...data } : row
            ),
        }));
    };

    const columns = [
        {
            dataField: "id",
            text: I18n.getTranslation(location, "matching.track.track.id"),
            sort: true,
            searchable: true,
            headerStyle: {
                width: "6rem",
            },
        },
        {
            dataField: "title",
            text: I18n.getTranslation(location, "matching.track.track.title"),
            formatter: (cell) => {
                return cell ?? "-";
            },
        },
        {
            dataField: "duration",
            text: I18n.getTranslation(
                location,
                "matching.track.track.duration"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
        },
        {
            dataField: "artist_album",
            text: I18n.getTranslation(
                location,
                "matching.track.track.artist_album"
            ),
            formatter: (cell) => {
                return cell === null ? (
                    "-"
                ) : (
                    <ul className="mb-0 pl-3">
                        {cell.map(({ artist, title }, index) => (
                            <li key={index}>
                                {artist} - {title}
                            </li>
                        ))}
                    </ul>
                );
            },
        },
        {
            dataField: "lyricfind.case",
            text: I18n.getTranslation(location, "matching.track.track.case"),
            formatter: (cell) => {
                return (
                    <span className="badge badge-primary badge-pill badge-lg">
                        {cell}
                    </span>
                );
            },
        },
        {
            dataField: "artist",
            text: I18n.getTranslation(
                location,
                "matching.track.track.ms_artist"
            ),
            // this only allows to (un)select an artist which will be used to
            // filter the recording search. This does not modifies any artist
            // matching
            formatter: (cell, row, rowIndex, { locale }) => {
                return (
                    <div style={{ maxWidth: "20rem" }}>
                        <ArtistSelect
                            id={cell?.id}
                            name={cell?.name}
                            locale={locale}
                            onMatch={(id, name) =>
                                updateRow(row.id, { artist: { id, name } })
                            }
                            onUnmatch={() =>
                                updateRow(row.id, { artist: null })
                            }
                        />
                    </div>
                );
            },
            formatExtraData: {
                locale,
            },
            filterValue: (cell) => cell?.id,
        },
        {
            dataField: "recording",
            text: I18n.getTranslation(
                location,
                "matching.track.track.recording"
            ),
            formatter: (cell, { id, artist }, rowIndex, { locale }) => {
                return (
                    <div style={{ maxWidth: "20rem" }}>
                        <RecordingMatcher
                            listId={listId}
                            rowId={id}
                            locale={locale}
                            artist={artist}
                            recording={cell}
                            onChange={(data) => updateRow(id, data)}
                        />
                    </div>
                );
            },
            formatExtraData: {
                locale,
            },
            filterValue: (cell) => cell?.id,
        },
        {
            dataField: "lyricfind",
            text: I18n.getTranslation(
                location,
                "matching.track.track.lyricfind"
            ),
            formatter: (cell, { id, recording }, rowIndex, { locale }) => {
                return (
                    <div style={{ maxWidth: "25rem" }}>
                        <LyricfindMatcher
                            listId={listId}
                            rowId={id}
                            locale={locale}
                            recording={recording}
                            lyricfind={cell}
                            onChange={(data) => updateRow(id, data)}
                        />
                    </div>
                );
            },
            formatExtraData: {
                locale,
            },
            filterValue: (cell) => cell?.id,
        },
        listId !== "global" && {
            dataField: "actions",
            isDummyField: true,
            text: I18n.getTranslation(location, "matching.track.track.actions"),
            formatter: (
                cell,
                {
                    id,
                    requested,
                    discarded,
                    comment,
                    created,
                    artist,
                    lyricfind,
                }
            ) => {
                return (
                    <ButtonGroup>
                        <RequestAction
                            listId={listId}
                            rowId={id}
                            requested={requested}
                            onChange={(data) => updateRow(id, data)}
                        />
                        <DiscardAction
                            listId={listId}
                            rowId={id}
                            discarded={discarded}
                            comment={comment}
                            matched={lyricfind !== null}
                            onChange={(data) => updateRow(id, data)}
                        />
                        <CreateAction
                            listId={listId}
                            rowId={id}
                            artistId={artist?.id}
                            created={created}
                            onChange={(data) => updateRow(id, data)}
                        />
                    </ButtonGroup>
                );
            },
        },
    ].filter(Boolean);

    const defaultSorted = [
        {
            dataField: "rank",
            order: "asc",
        },
    ];

    const pagination = paginationFactory({
        custom: true,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        sizePerPage: 50,
        nextPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.next_page"
        ),
        prePageTitle: I18n.getTranslation(
            location,
            "utils.pagination.previous_page"
        ),
        firstPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.first_page"
        ),
        lastPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.last_page"
        ),
        paginationTotalRenderer: (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                <I18n t="utils.pagination.total" args={{ size }} />
            </span>
        ),
    });

    const rowClasses = ({ recording, lyricfind, discarded }) => {
        if (discarded) {
            return "table-danger";
        } else if (recording === null && lyricfind === null) {
            return null;
        } else if (recording === null || lyricfind === null) {
            return "table-warning";
        } else if (lyricfind.case === 1) {
            return "table-success";
        } else {
            return "table-primary";
        }
    };

    return (
        <DeezerTrack
            {...props}
            data={getFilteredData({ data, filter })}
            isLoading={isLoading}
            columns={columns}
            defaultSorted={defaultSorted}
            pagination={pagination}
            rowClasses={rowClasses}
            location={location}
            filter={filter}
            setFilter={setFilter}
            listId={listId}
            query={query}
            setQuery={setQuery}
        />
    );
};

export default EnhancedDeezerTrack;
