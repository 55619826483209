import React from "react";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";
import { Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faCheck } from "@fortawesome/free-solid-svg-icons";
import InputText from "shared/components/Form/InputText";
import InputSelect from "shared/components/Form/InputSelect";
import { Controller } from "react-hook-form";
import Tippy from "@tippyjs/react";
import clsx from "clsx";

const TextForm = ({
    isLoading,
    handleSubmit,
    errors,
    register,
    control,
    isSubmitting,
    isDirty,
    onSubmit,
    onDiscard,
    location,
}) => {
    return (
        <Form onSubmit={!isSubmitting ? handleSubmit(onSubmit) : null}>
            <div className="d-flex">
                <Form.Group className="col d-flex align-items-center mr-2 mb-0 p-0">
                    <InputText
                        {...register("field")}
                        error={
                            errors.field && [
                                I18n.getTranslation(
                                    location,
                                    errors.field.message
                                ),
                            ]
                        }
                    />
                </Form.Group>
                <div className="d-flex align-items-center">
                    <Tippy
                        content={I18n.getTranslation(
                            location,
                            "artist.management.conflict.resolution.accept_incoming.title"
                        )}
                        trigger="mouseenter"
                    >
                        <Button
                            variant="success"
                            size="sm"
                            className={clsx("mr-1")}
                            onClick={handleSubmit(onSubmit)}
                        >
                            <FontAwesomeIcon icon={faCheck} fixedWidth />
                        </Button>
                    </Tippy>

                    <Tippy
                        content={I18n.getTranslation(
                            location,
                            "artist.management.conflict.resolution.discard.title"
                        )}
                        trigger="mouseenter"
                    >
                        <Button
                            variant="danger"
                            size="sm"
                            className={clsx("ml-1")}
                            onClick={() => onDiscard()}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} fixedWidth />
                        </Button>
                    </Tippy>
                </div>
            </div>
        </Form>
    );
};

export default TextForm;
