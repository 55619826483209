import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import UncheckButton from "./UncheckButton";

const EnhancedUncheckButton = ({
    listId,
    songId,
    onChange,
    locale,
    ...props
}) => {
    const { api } = useAPI();
    const [show, setShow] = useState(false);

    const {
        handleSubmit,
        register,
        reset,
        formState: { isSubmitting },
    } = useForm({
        defaultValues: {
            comment: "",
        },
    });

    const onShow = () => {
        reset({ comment: "" });
        setShow(true);
    };
    const onHide = () => setShow(false);

    const onSubmit = useCallback(
        (comment) =>
            api
                .post(
                    `matching/track/universal/list/${listId}/uncheck`,
                    { locale },
                    {
                        songId,
                        comment,
                    }
                )
                .then((response) => {
                    onChange(response);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                }),
        [listId, songId, locale]
    );

    return (
        <UncheckButton
            show={show}
            onShow={onShow}
            onHide={onHide}
            register={register}
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit(({ comment }) =>
                onSubmit(comment).then(onHide)
            )}
            {...props}
        />
    );
};

export default EnhancedUncheckButton;
