import React from "react";
import RecordingSelect from "shared/components/RecordingSelect";

const RecordingMatcher = ({ recording, defaultSearch, locale, onChange }) => {
    return (
        <RecordingSelect
            id={recording?.id}
            title={recording?.title}
            locale={locale}
            menuPlacement="auto"
            enablePortal
            defaultSearch={defaultSearch}
            onChange={onChange}
        />
    );
};

export default RecordingMatcher;
