import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Controller } from "react-hook-form";
import InputSelect from "shared/components/Form/InputSelect";
import InputText from "shared/components/Form/InputText";
import I18n from "shared/lib/I18n";

const CreateTaskButton = ({
    rowCount,
    userOptions,
    show,
    onShow,
    onHide,
    register,
    control,
    onSubmit,
    isSubmitting,
    isDirty,
    errors,
}) => {
    return (
        <>
            <Button disabled={!rowCount} onClick={onShow}>
                <I18n
                    t="matching.assignment.universal.track.track_list.create_task.head"
                    args={rowCount}
                />
            </Button>

            <Modal show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <I18n
                            t="matching.assignment.universal.track.track_list.create_task.head"
                            args={rowCount}
                        />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={!isSubmitting ? onSubmit : null}>
                        <Form.Group>
                            <Form.Label>
                                <I18n t="matching.assignment.universal.track.track_list.create_task.title" />
                            </Form.Label>
                            <InputText
                                {...register("title")}
                                error={
                                    errors.title && [
                                        I18n.getTranslation(
                                            location,
                                            errors.title.message
                                        ),
                                    ]
                                }
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                <I18n t="matching.assignment.universal.track.track_list.create_task.user" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name="user"
                                render={({
                                    field: { onChange, value, ref },
                                }) => (
                                    <InputSelect
                                        innerRef={ref}
                                        value={value}
                                        options={userOptions}
                                        onChange={onChange}
                                        isClearable={false}
                                        error={
                                            errors.user && [
                                                I18n.getTranslation(
                                                    location,
                                                    errors.user.message
                                                ),
                                            ]
                                        }
                                    />
                                )}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="success"
                        disabled={isSubmitting || !isDirty}
                        onClick={!isSubmitting ? onSubmit : null}
                    >
                        <I18n t="utils.button.create" />
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CreateTaskButton;
