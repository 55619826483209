import React, { useContext } from "react";
import Log from "./Log";
import ConflictValue from "../ConflictValue/ConflictValue.container";
import I18n from "shared/lib/I18n";
import { useLocation } from "react-router";
import { ArtistManagementContext } from "pages/ArtistManagement";

const EnhancedLog = (props) => {
    const { artist } = useContext(ArtistManagementContext);
    const location = useLocation();

    const columns = [
        {
            dataField: "created_at",
            text: I18n.getTranslation(
                location,
                "artist.management.conflict.log.date"
            ),
            sort: true,
            formatter: (cell) => cell ?? "-",
        },
        {
            dataField: "username",
            text: I18n.getTranslation(
                location,
                "artist.management.conflict.log.username"
            ),
            sort: true,
            formatter: (cell) => cell ?? "-",
        },
        {
            dataField: "partner",
            text: I18n.getTranslation(
                location,
                "artist.management.conflict.log.origin"
            ),
            sort: true,
        },
        {
            dataField: "action",
            text: I18n.getTranslation(
                location,
                "artist.management.conflict.log.action"
            ),
            sort: true,
            formatter: (cell) => (
                <I18n
                    t={`artist.management.conflict.log.${cell.replaceAll(
                        ".",
                        "_"
                    )}`}
                />
            ),
        },
        {
            dataField: "field_name",
            text: I18n.getTranslation(
                location,
                "artist.management.conflict.log.field"
            ),
            sort: true,
            formatter: (cell) => (
                <I18n
                    t={`artist.management.conflict.log.field_name.${cell}`}
                    args={{
                        artistType: artist.type,
                    }}
                />
            ),
        },
        {
            dataField: "old_value",
            text: I18n.getTranslation(
                location,
                "artist.management.conflict.log.old_value"
            ),
            sort: true,
            formatter: (cell, row) => (
                <ConflictValue field_name={row.field_name} value={cell} />
            ),
        },
        {
            dataField: "new_value",
            text: I18n.getTranslation(
                location,
                "artist.management.conflict.log.new_value"
            ),
            sort: true,
            formatter: (cell, row) => (
                <ConflictValue field_name={row.field_name} value={cell} />
            ),
        },
    ];

    const options = {
        sizePerPage: 30,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        disablePageTitle: true,
        hideSizePerPage: true,
        paginationTotalRenderer: (from, to, size) => (
            <div className="ml-3 mb-3">
                {I18n.getTranslation(location, "utils.pagination.total", {
                    size,
                })}
            </div>
        ),
    };

    return <Log {...props} columns={columns} options={options} />;
};

export default EnhancedLog;
