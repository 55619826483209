import React, { useState, useEffect, useCallback } from "react";
import { useParams, useLocation } from "react-router";
import I18n from "shared/lib/I18n";
import Wikidata from "./Wikidata";
import useAPI from "shared/hooks/useApi";
import useIntParams from "shared/hooks/useIntParams";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const defaultState = {
    partnerId: null,
    artistName: "",
    isLoading: false,
};

const defaultModalState = {
    isModalLoading: false,
    firewall: null,
};

const schema = yup.object().shape({
    wikipedia: yup.string().nullable(),
});

const EnhancedWikidata = ({ onSuccess, ...props }) => {
    const { api } = useAPI();
    const { locale } = useParams();
    const location = useLocation();

    const [showISNISearch, setShowISNISearch] = useState(false);
    const onShowISNISearch = () => setShowISNISearch(true);
    const onHideISNISearch = () => setShowISNISearch(false);

    const [state, setState] = useState(defaultState);
    const [modalState, setModalState] = useState(defaultModalState);
    const { artistId } = useIntParams();
    const {
        handleSubmit,
        register,
        reset,
        watch,
        setValue,
        getValues,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            wikipedia: null,
        },
    });

    const getWikidata = useCallback(() => {
        let cancelled = false;

        setState((prev) => ({ ...prev, isLoading: true }));
        api.get(`matching/ms/wikidata/${artistId}`, { locale })
            .then((response) => {
                if (!cancelled) {
                    Object.keys(response).length > 0
                        ? setState({
                              isLoading: false,
                              partnerId: response.partnerId,
                              artistName: response.artistName,
                          })
                        : setState(defaultState);
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [artistId, locale]);

    useEffect(getWikidata, [getWikidata]);

    const onSubmit = async (data) => {
        setModalState((prev) => ({ ...prev, isModalLoading: true }));
        onShowISNISearch();
        await api
            .post(`wikidata/${artistId}`, { locale }, data)
            .then((response) => {
                setModalState({
                    isModalLoading: false,
                    firewall: response,
                });
                toast.success(
                    I18n.getTranslation(
                        location,
                        `artist.management.wikidata.update.success`
                    )
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        `artist.management.wikidata.update.error`,
                        error.message
                    )
                );
                setModalState(defaultModalState);
            });
    };

    const handleDelete = () => {
        swal({
            title: I18n.getTranslation(
                location,
                "artist.management.wikidata.del.alert.title"
            ),
            text: I18n.getTranslation(
                location,
                "artist.management.wikidata.del.alert.text"
            ),
            icon: "error",
            dangerMode: true,
            buttons: {
                cancel: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.cancel"
                    ),
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.confirm"
                    ),
                    closeModal: true,
                },
            },
        }).then((isConfirm) => {
            if (!isConfirm) {
                return;
            }
            api.delete(`matching/ms/wikidata/${artistId}`, { locale })
                .then((response) => {
                    toast.success(
                        I18n.getTranslation(
                            location,
                            "artist.management.wikidata.del.success"
                        )
                    );
                    onSuccess?.();
                    setState(defaultState);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(
                        I18n.getTranslation(
                            location,
                            "artist.management.wikidata.del.error",
                            error.message
                        )
                    );
                });
        });
    };

    return (
        <Wikidata
            {...props}
            {...state}
            {...modalState}
            getWikidata={getWikidata}
            handleSubmit={handleSubmit}
            errors={errors}
            register={register}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            onSubmit={onSubmit}
            location={location}
            showISNISearch={showISNISearch}
            onHideISNISearch={onHideISNISearch}
            handleDelete={handleDelete}
        />
    );
};

export default EnhancedWikidata;
