import React from "react";
import I18n from "shared/lib/I18n";
import { Nav, Tab } from "react-bootstrap";
import Resolution from "./Resolution";
import Log from "./Log";
import "./Conflicts.css";

const Conflicts = ({
    setConflictState,
    selectedTab,
    onSelectTab,
    isConflictLoading,
    conflicts,
    isLogLoading,
    logs,
    getLogs,
}) => {
    return (
        <div className="conflicts">
            <Tab.Container
                id="conflicts-tab"
                activeKey={selectedTab}
                defaultActiveKey="resolution"
                onSelect={onSelectTab}
            >
                <Nav variant="pills" justify>
                    <Nav.Item>
                        <Nav.Link eventKey="resolution">
                            <I18n t="artist.management.conflict.resolution.head" />
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="log">
                            <I18n t="artist.management.conflict.log.head" />
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content className="nav-content">
                    <Tab.Pane eventKey="resolution" className="nav-pane">
                        <Resolution
                            setState={setConflictState}
                            isLoading={isConflictLoading}
                            conflicts={conflicts}
                            onSuccess={() => {
                                getLogs();
                            }}
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey="log" className="nav-pane">
                        <Log isLoading={isLogLoading} logs={logs} />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </div>
    );
};

export default Conflicts;
