import React from "react";
import { useLocation } from "react-router";
import I18n from "shared/lib/I18n";
import StatusFilter from "./StatusFilter";

const EnhancedStatusFilter = (props) => {
    const location = useLocation();
    const filterOptions = [
        {
            label: I18n.getTranslation(
                location,
                "matching.assignment.universal.track.track_list.filter.status.checked"
            ),
            value: "checked",
        },
        {
            label: I18n.getTranslation(
                location,
                "matching.assignment.universal.track.track_list.filter.status.discarded"
            ),
            value: "discarded",
        },
        {
            label: I18n.getTranslation(
                location,
                "matching.assignment.universal.track.track_list.filter.status.assigned"
            ),
            value: "assigned",
        },
        {
            label: I18n.getTranslation(
                location,
                "matching.assignment.universal.track.track_list.filter.status.unassigned"
            ),
            value: "unassigned",
        },
    ];

    return <StatusFilter {...props} filterOptions={filterOptions} />;
};

export default EnhancedStatusFilter;
