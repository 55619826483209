import React, { useCallback } from "react";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import AlbumMatcher from "./AlbumMatcher";

const EnhancedAlbumMatcher = ({
    listId,
    songId,
    candidates,
    onChange,
    locale,
    ...props
}) => {
    const { api } = useAPI();

    const onMatch = useCallback(
        (opt, action) => {
            if (action.action !== "select-option") {
                return;
            }

            api.post(
                `matching/track/universal/list/${listId}/album`,
                { locale },
                {
                    partnerId: songId,
                    msId: opt.value,
                    state: "VALID",
                }
            )
                .then((response) => {
                    onChange(response);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                });
        },
        [listId, songId, locale]
    );

    const options = (candidates ?? []).map((album) => {
        return {
            value: album.id,
            label: [album.title, album.type, album.format, album.release_date]
                .filter(Boolean)
                .join(" - "),
        };
    });

    return (
        <AlbumMatcher
            onChange={onMatch}
            options={options}
            disabled={!candidates}
            {...props}
        />
    );
};

export default EnhancedAlbumMatcher;
