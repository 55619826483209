import React, { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useAPI from "shared/hooks/useApi";
import useUser from "shared/hooks/useUser";
import { isValidDate } from "shared/functions/Date";
import ReleaseDateForm from "./ReleaseDateForm";

const schema = yup.object().shape({
    release_date: yup
        .string()
        .nullable()
        .trim()
        .required("utils.form.required")
        .test("is-a-valid-date", "utils.form.date_format", isValidDate),
});

const EnhancedReleaseDateForm = ({
    listId,
    songId,
    recording,
    genre,
    album,
    checked,
    discarded,
    locale,
    onChange,
    ...props
}) => {
    const { api } = useAPI();
    const { user } = useUser();

    const {
        handleSubmit,
        reset,
        register,
        formState: { isSubmitting, isDirty, isValid, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            release_date: "",
        },
    });

    useEffect(() => {
        reset({
            release_date: album?.release_date ?? "",
        });
    }, [album]);

    const onSave = useCallback(
        ({ release_date }) => {
            api.post(
                `matching/track/universal/list/${listId}/release_date`,
                { locale },
                {
                    albumId: album.id,
                    releaseDate: release_date,
                }
            )
                .then((response) => {
                    onChange(response);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                });
        },
        [listId, album, locale]
    );

    const onCheck = useCallback(
        ({ release_date }) => {
            api.post(
                `matching/track/universal/list/${listId}/check`,
                { locale },
                {
                    partnerId: songId,
                    recordingId: recording.id,
                    albumId: album.id,
                    genreId: genre.id,
                    releaseDate: release_date,
                }
            )
                .then((response) => {
                    onChange(response);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                });
        },
        [listId, songId, recording, album, genre, locale]
    );

    const onDiscard = useCallback(
        (comment) =>
            api
                .post(
                    `matching/track/universal/list/${listId}/discard`,
                    { locale },
                    {
                        songId,
                        comment,
                    }
                )
                .then((response) => {
                    onChange(response);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                }),
        [listId, songId, locale]
    );

    const onRecover = useCallback(
        () =>
            api
                .post(
                    `matching/track/universal/list/${listId}/recover`,
                    { locale },
                    {
                        songId,
                    }
                )
                .then((response) => {
                    onChange(response);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                }),
        [listId, songId, locale]
    );

    return (
        <ReleaseDateForm
            listId={listId}
            songId={songId}
            register={register}
            onSave={handleSubmit(onSave)}
            onCheck={handleSubmit(onCheck)}
            onDiscard={onDiscard}
            onRecover={onRecover}
            onChange={onChange}
            inputDisabled={album === null}
            saveDisabled={
                album === null || isSubmitting || !isDirty || !isValid
            }
            checkDisabled={
                recording === null ||
                album === null ||
                genre === null ||
                genre.error ||
                checked ||
                discarded ||
                !isValid
            }
            discardDisabled={checked}
            showUncheck={checked && user.hasRight("plannings.manage")}
            discarded={discarded}
            errors={errors}
            locale={locale}
            {...props}
        />
    );
};

export default EnhancedReleaseDateForm;
