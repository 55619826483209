import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import TrackList from "./TrackList";
import { getFilteredData } from "./TrackList.selector";

const defaultState = {
    data: [],
    isLoading: false,
};

const EnhancedTrackList = ({ date, ...props }) => {
    const { api } = useAPI();
    const location = useLocation();
    const [{ data, isLoading }, setState] = useState(defaultState);
    const [filter, setFilter] = useState({
        status: null,
        country: null,
        assignedTo: null,
        lines: null,
    });

    const fetchTracks = useCallback(() => {
        let cancelled = false;
        setState((prev) => ({ ...prev, isLoading: true }));
        api.get(`matching/assignment/universal/track/top/${date}`)
            .then((response) => {
                if (!cancelled) {
                    setState({ data: response, isLoading: false });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    setState(defaultState);
                    console.error(error);
                    toast.error(error.message);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [date]);

    useEffect(() => {
        fetchTracks();
    }, [fetchTracks]);

    const countryOptions = useMemo(
        () =>
            [...new Set(data.map(({ country }) => country))].map((country) => ({
                value: country,
                label: country,
            })),
        [data]
    );

    const assignedToOptions = useMemo(
        () =>
            [
                ...new Set(
                    data
                        .map(({ assigned_to }) => assigned_to)
                        .filter((assigned_to) => assigned_to)
                ),
            ].map((assigned_to) => ({
                value: assigned_to,
                label: assigned_to,
            })),
        [data]
    );

    const columns = [
        {
            dataField: "rank",
            text: I18n.getTranslation(
                location,
                "matching.assignment.universal.track.track_list.rank"
            ),
            sort: true,
            headerStyle: {
                width: "5rem",
            },
        },
        {
            dataField: "artist_name",
            text: I18n.getTranslation(
                location,
                "matching.assignment.universal.track.track_list.artist"
            ),
        },
        {
            dataField: "title",
            text: I18n.getTranslation(
                location,
                "matching.assignment.universal.track.track_list.title"
            ),
        },
        {
            dataField: "country",
            text: I18n.getTranslation(
                location,
                "matching.assignment.universal.track.track_list.country"
            ),
        },
        {
            dataField: "assigned_to",
            text: I18n.getTranslation(
                location,
                "matching.assignment.universal.track.track_list.assigned_to"
            ),
        },
    ];

    const defaultSorted = [
        {
            dataField: "rank",
            order: "asc",
        },
    ];

    const pagination = paginationFactory({
        custom: true,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        sizePerPage: 50,
        nextPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.next_page"
        ),
        prePageTitle: I18n.getTranslation(
            location,
            "utils.pagination.previous_page"
        ),
        firstPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.first_page"
        ),
        lastPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.last_page"
        ),
        paginationTotalRenderer: (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                <I18n t="utils.pagination.total" args={{ size }} />
            </span>
        ),
    });

    const rowClasses = ({ status }) => {
        if (status == "checked") {
            return "table-success";
        } else if (status == "discarded") {
            return "table-secondary";
        } else if (status == "assigned") {
            return "table-warning";
        }
    };

    const filteredData = getFilteredData({ data, filter });
    const rowIds = useMemo(
        () =>
            filteredData
                .filter(({ assigned_to }) => !assigned_to)
                .map(({ id }) => id),
        [filteredData]
    );

    const onCreateTask = (newData) => {
        setState((prev) => ({
            ...prev,
            data: prev.data.map(
                (row) => newData.find(({ id }) => id === row.id) ?? row
            ),
        }));
    };

    return (
        <TrackList
            date={date}
            data={filteredData}
            rowIds={rowIds}
            isLoading={isLoading}
            columns={columns}
            defaultSorted={defaultSorted}
            pagination={pagination}
            rowClasses={rowClasses}
            filter={filter}
            setFilter={setFilter}
            countryOptions={countryOptions}
            assignedToOptions={assignedToOptions}
            onCreateTask={onCreateTask}
            {...props}
        />
    );
};

export default EnhancedTrackList;
