import React, { useCallback } from "react";
import { toast } from "react-toastify";
import { useDebouncedCallback } from "use-debounce";
import useAPI from "shared/hooks/useApi";
import GenreMatcher from "./GenreMatcher";

const EnhancedGenreMatcher = ({
    listId,
    recordingId,
    onChange,
    locale,
    ...props
}) => {
    const { api } = useAPI();

    const searchGenre = useDebouncedCallback((term, callback) => {
        api.get("matching/track/universal/search/genre", { term, locale })
            .then((response) => {
                callback(
                    response.map(({ id, name }) => {
                        return {
                            value: id,
                            label: name,
                        };
                    })
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, 250);

    const onMatch = useCallback(
        (opt, action) => {
            if (action.action !== "select-option") {
                return;
            }

            api.post(
                `matching/track/universal/list/${listId}/genre`,
                { locale },
                {
                    recordingId,
                    genreId: opt.value,
                }
            )
                .then((response) => {
                    onChange(response);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                });
        },
        [listId, recordingId, locale]
    );

    return (
        <GenreMatcher
            searchGenre={searchGenre}
            onChange={onMatch}
            disabled={!recordingId}
            locale={locale}
            {...props}
        />
    );
};

export default EnhancedGenreMatcher;
