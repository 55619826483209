import React from "react";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";

const Resolution = ({ isLoading, conflicts, columns }) => {
    return (
        <Widget isLoading={isLoading}>
            <Widget.Body className="widget-table-overflow">
                <BootstrapTable
                    keyField="id"
                    columns={columns}
                    data={conflicts}
                    classes="table-header-fixed table-layout-auto"
                    bordered={false}
                    bootstrap4
                    striped
                    hover
                    noDataIndication={
                        <I18n t="artist.management.conflict.resolution.empty" />
                    }
                />
            </Widget.Body>
        </Widget>
    );
};

export default Resolution;
