import React from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "shared/lib/I18n";
import I18n from "shared/lib/I18n";
import List from "./List";

const MatchingEvent = () => {
    return (
        <div className="content-wrap">
            <Helmet>
                <title>{`${I18n.getTranslation(
                    location,
                    "dashboard"
                )} - ${I18n.getTranslation(
                    location,
                    "base.nav.matching.title"
                )} - ${I18n.getTranslation(
                    location,
                    "base.nav.matching.event"
                )}`}</title>
            </Helmet>
            <main id="content" className="content" role="main">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    <li className="breadcrumb-item">
                        <I18n t="base.nav.matching.title" />
                    </li>
                    <li className="breadcrumb-item active">
                        <NavLink to="/matching/event">
                            <I18n t="base.nav.matching.event" />
                        </NavLink>
                    </li>
                </ol>
                <div className="row">
                    <div className="col">
                        <List />
                    </div>
                </div>
            </main>
        </div>
    );
};

export default MatchingEvent;
