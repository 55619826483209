import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useAPI from "shared/hooks/useApi";
import CreateTaskButton from "./CreateTaskButton";

const schema = yup.object().shape({
    title: yup.string().trim().ensure().required("utils.form.required"),
    user: yup.object().required("utils.form.required"),
});

const EnhancedCreateTaskButton = ({
    rowIds,
    defaultTitle,
    onSuccess,
    ...props
}) => {
    const { api } = useAPI();
    const [show, setShow] = useState(false);
    const [userOptions, setUserOptions] = useState([]);

    const fetchUserOptions = useCallback(() => {
        let cancelled = false;
        api.get("plannings/tasks/assignement")
            .then((response) => {
                if (!cancelled) {
                    setUserOptions(
                        response.map(({ id, name }) => ({
                            value: id,
                            label: name,
                        }))
                    );
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    setUserOptions([]);
                    console.error(error);
                    toast.error(error.message);
                }
            });

        return () => {
            cancelled = true;
        };
    }, []);

    useEffect(() => {
        fetchUserOptions();
    }, [fetchUserOptions]);

    const {
        handleSubmit,
        register,
        control,
        reset,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            title: "",
            user: null,
        },
    });

    const onShow = () => {
        reset({
            title: defaultTitle,
            user: null,
        });
        setShow(true);
    };
    const onHide = () => setShow(false);

    const onSubmit = ({ title, user }) => {
        api.post(
            "matching/assignment/universal/track/task",
            {},
            { title, userId: user.value, rowIds }
        )
            .then((response) => {
                onSuccess(response);
                onHide();
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    };

    return (
        <CreateTaskButton
            rowCount={rowIds.length}
            userOptions={userOptions}
            show={show}
            onShow={onShow}
            onHide={onHide}
            register={register}
            control={control}
            onSubmit={handleSubmit(onSubmit)}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            errors={errors}
            {...props}
        />
    );
};

export default EnhancedCreateTaskButton;
