import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import { getFilteredData } from "./List.selector";
import List from "./List";
import SongkickEventMatcher from "./SongkickEventMatcher";
import ArtistsMatcher from "./ArtistsMatcher";

const defaultState = {
    data: [],
    isLoading: false,
};

const EnhancedList = (props) => {
    const { api } = useAPI();
    const location = useLocation();
    const { locale } = useParams();
    const [{ data, isLoading }, setState] = useState(defaultState);
    const [filter, setFilter] = useState({
        completed: null,
    });

    const fetchEvents = useCallback(() => {
        let cancelled = false;
        setState((prev) => ({ ...prev, isLoading: true }));
        api.get(`matching/event/francebillet`)
            .then((response) => {
                if (!cancelled) {
                    setState({
                        data: response,
                        isLoading: false,
                    });
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    setState(defaultState);
                    console.error(error);
                    toast.error(error.message);
                }
            });

        return () => {
            cancelled = true;
        };
    }, []);

    useEffect(() => {
        fetchEvents();
    }, [fetchEvents]);

    const updateRows = (newData) => {
        setState((prev) => ({
            ...prev,
            data: prev.data.map(
                (row) => newData.find(({ id }) => id === row.id) ?? row
            ),
        }));
    };

    const columns = [
        {
            dataField: "id",
            text: I18n.getTranslation(location, "matching.event.id"),
            formatter: (cell, { url }) => (
                <a href={url} target="_blank" rel="noopener noreferrer">
                    {cell}
                </a>
            ),
            sort: true,
        },
        {
            dataField: "name",
            text: I18n.getTranslation(location, "matching.event.name"),
        },
        {
            dataField: "date",
            text: I18n.getTranslation(location, "matching.event.date"),
        },
        {
            dataField: "time",
            text: I18n.getTranslation(location, "matching.event.time"),
        },
        {
            dataField: "venue",
            text: I18n.getTranslation(location, "matching.event.venue"),
            formatter: ({ name, country, city }) =>
                `${country} - ${city} - ${name}`,
            filterValue: ({ name, country, city }) => [name, country, city],
        },
        {
            dataField: "songkickEvents",
            text: I18n.getTranslation(
                location,
                "matching.event.songkick_events"
            ),
            formatter: (cell, { id, name, date, venue, discarded }) => (
                <SongkickEventMatcher
                    francebilletId={id}
                    events={cell}
                    date={date}
                    defaultSearch={`${name} ${venue?.name} ${venue?.city}`}
                    onChange={updateRows}
                    discarded={discarded}
                />
            ),
        },
    ];

    const expandRow = {
        renderer: ({ artists }) => (
            <ArtistsMatcher
                artists={artists}
                onChange={updateRows}
                locale={locale}
            />
        ),
        showExpandColumn: true,
        expandByColumnOnly: true,
        expandHeaderColumnRenderer: () => undefined,
        expandColumnRenderer: ({ expandable, expanded }) =>
            expandable ? (
                <FontAwesomeIcon
                    icon={expanded ? faMinus : faPlus}
                    className="cursor-pointer"
                    fixedWidth
                />
            ) : undefined,
    };

    const defaultSorted = [
        {
            dataField: "id",
            order: "asc",
        },
    ];

    const pagination = paginationFactory({
        custom: true,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        sizePerPage: 50,
        nextPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.next_page"
        ),
        prePageTitle: I18n.getTranslation(
            location,
            "utils.pagination.previous_page"
        ),
        firstPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.first_page"
        ),
        lastPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.last_page"
        ),
        paginationTotalRenderer: (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                <I18n t="utils.pagination.total" args={{ size }} />
            </span>
        ),
    });

    const rowClasses = ({ status }) => {
        if (status == "matched") {
            return "table-success";
        }
        if (status == "discarded") {
            return "table-secondary";
        }
    };

    return (
        <List
            data={getFilteredData({ data, filter })}
            isLoading={isLoading}
            columns={columns}
            defaultSorted={defaultSorted}
            pagination={pagination}
            rowClasses={rowClasses}
            expandRow={expandRow}
            location={location}
            filter={filter}
            setFilter={setFilter}
        />
    );
};

export default EnhancedList;
