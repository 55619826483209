import React from "react";
import InputAsyncSelect from "shared/components/Form/InputAsyncSelect";
import I18n from "shared/lib/I18n";

const GenreMatcher = ({
    genre,
    locale,
    location,
    disabled,
    searchGenre,
    onChange,
}) => {
    return (
        <div
            title={
                genre?.error
                    ? I18n.getTranslation(
                          location,
                          "matching.track.track.genre_is_parent"
                      )
                    : ""
            }
        >
            <InputAsyncSelect
                value={genre !== null && { value: genre.id, label: genre.name }}
                loadOptions={searchGenre}
                locale={locale}
                onChange={onChange}
                isClearable={false}
                isDisabled={disabled}
                error={
                    genre?.error && [
                        I18n.getTranslation(
                            location,
                            "matching.track.track.genre_is_parent"
                        ),
                    ]
                }
                showError={false}
            />
        </div>
    );
};

export default GenreMatcher;
