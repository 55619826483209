import React from "react";
import I18n from "shared/lib/I18n";
import ArtistSelect from "shared/components/ArtistSelect";
import DiscardButton from "shared/components/DiscardButton";

const ArtistsMatcher = ({
    artists,
    onChange,
    onDiscard,
    onRecover,
    locale,
}) => {
    if (!artists) {
        return (
            <div className="font-italic text-center">
                <I18n t="matching.event.no_artists" />
            </div>
        );
    }

    return artists?.map((artist) => (
        <div className="row mb-2" key={`${artist.partner}-${artist.id}`}>
            <div className="col align-self-center">
                <a href={artist.url}>{artist.name}</a> ({artist.displayPartner})
            </div>
            <div className="col d-flex">
                <div className="flex-grow-1">
                    <ArtistSelect
                        isDisabled={artist.discarded !== null}
                        id={artist.msArtist?.id}
                        name={artist.msArtist?.name}
                        onMatch={(id, name) =>
                            onChange(artist.partner, artist.id, id, "VALID")
                        }
                        onUnmatch={() =>
                            onChange(
                                artist.partner,
                                artist.id,
                                artist.msArtist.id,
                                "INVALID"
                            )
                        }
                        defaultSearch={artist.name}
                        locale={locale}
                        enablePortal
                    />
                </div>
                <div className="ml-2">
                    <DiscardButton
                        discarded={artist.discarded !== null}
                        comment={artist.discarded?.comment}
                        onDiscard={(comment) =>
                            onDiscard(artist.partner, artist.id, comment)
                        }
                        onRecover={() => onRecover(artist.partner, artist.id)}
                        disabled={
                            artist.discarded === null &&
                            artist.msArtists?.length > 0
                        }
                    />
                </div>
            </div>
        </div>
    ));
};

export default ArtistsMatcher;
