import React from "react";
import InputAsyncSelect from "shared/components/Form/InputAsyncSelect";

const ArtistSelect = ({ id, name, loadOptions, onChange, ...props }) => {
    return (
        <InputAsyncSelect
            value={
                id !== null &&
                typeof id !== "undefined" &&
                name !== null &&
                typeof name !== "undefined"
                    ? {
                          value: { id, name },
                          label: `${name} (${id})`,
                      }
                    : null
            }
            loadOptions={loadOptions}
            onChange={onChange}
            {...props}
        />
    );
};

export default ArtistSelect;
