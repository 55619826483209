import React from "react";
import InputAsyncSelect from "shared/components/Form/InputAsyncSelect";
import DiscardButton from "shared/components/DiscardButton";

const SongkickEventMatcher = ({
    events,
    defaultSearch,
    loadOptions,
    onChange,
    discarded,
    onDiscard,
    onRecover,
}) => {
    return (
        <div className="d-flex">
            <div className="flex-grow-1">
                <InputAsyncSelect
                    isDisabled={discarded !== null}
                    value={
                        events?.map((value) => ({
                            value,
                            label: [
                                value.name,
                                value.date,
                                value.time,
                                value.venue?.country,
                                value.venue?.city,
                            ]
                                .filter(Boolean)
                                .join(" - "),
                        })) ?? []
                    }
                    loadOptions={loadOptions}
                    onChange={onChange}
                    defaultSearch={defaultSearch}
                    isMulti
                    isClearable={false}
                    enablePortal
                />
            </div>
            <div className="ml-2 align-self-center">
                <DiscardButton
                    discarded={discarded !== null}
                    comment={discarded?.comment}
                    onDiscard={onDiscard}
                    onRecover={onRecover}
                    disabled={discarded === null && events?.length > 0}
                />
            </div>
        </div>
    );
};

export default SongkickEventMatcher;
