import React from "react";
import { useParams } from "react-router";
import UniversalTrackAssignment from "./UniversalTrackAssignment";

const EnhancedUniversalTrackAssignment = (props) => {
    const { date } = useParams();

    return <UniversalTrackAssignment date={date} {...props} />;
};

export default EnhancedUniversalTrackAssignment;
