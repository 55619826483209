import React from "react";
import { useParams, useLocation } from "react-router";
import DownloadButton from "./DownloadButton";
import useAPI from "shared/hooks/useApi";
import useUser from "shared/hooks/useUser";
import { toast } from "react-toastify";
import I18n from "shared/lib/I18n";

const EnhancedDownloadButton = ({ picture, original = false, ...props }) => {
    const { api } = useAPI();
    const { locale } = useParams();
    const location = useLocation();
    const { user } = useUser();

    const onClick = async () => {
        if (
            user.hasRight(
                `api.musicstory.picture.${picture.source.toLowerCase()}`
            ) &&
            user.hasRight("picture.download")
        ) {
            try {
                const uuid = original
                    ? picture.uuid
                    : `${picture.uuid}/${picture.uuid_version}`;
                const response = await api.get_raw("picture/protected", {
                    locale,
                    source: picture.source,
                    uuid,
                    extension: picture.extension,
                    responseType: "blob",
                });

                if (response.ok) {
                    const blob = await response.blob();
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = `${picture.uuid}.${picture.extension}`;
                    link.click();
                    URL.revokeObjectURL(url);
                } else {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
            } catch (err) {
                console.error("Download failed:", err);
            }
        } else {
            toast.error(
                I18n.getTranslation(
                    location,
                    `artist.view.imagesGallery.detail.download.error`
                )
            );
        }
    };

    return <DownloadButton onClick={onClick} {...props} />;
};

export default EnhancedDownloadButton;
