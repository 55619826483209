import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputTextArea from "shared/components/Form/InputTextArea";
import I18n from "shared/lib/I18n";

const UncheckButton = ({
    show,
    onShow,
    onHide,
    register,
    isSubmitting,
    onSubmit,
    location,
}) => {
    return (
        <>
            <Button
                variant="danger"
                onClick={onShow}
                title={I18n.getTranslation(
                    location,
                    "matching.track.track.uncheck.button.title"
                )}
            >
                <FontAwesomeIcon icon={faTimes} fixedWidth />
            </Button>

            <Modal show={show} onHide={onHide} scrollable>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <I18n t="matching.track.track.uncheck.modal.head" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>
                                <I18n t="matching.track.track.uncheck.modal.comment" />
                            </Form.Label>
                            <InputTextArea {...register("comment")} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="success"
                        disabled={isSubmitting}
                        onClick={onSubmit}
                    >
                        <I18n t="matching.track.track.uncheck.modal.submit" />
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default UncheckButton;
